import React, { FunctionComponent, ReactNode } from 'react';
import { Button, Dialog, DialogActions, DialogContent, IconButton, useMediaQuery, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import ColorPalette from '@packages/lighthouse-theme/src/ColorPalette';

interface IResponsiveModalProps {
  children: ReactNode;
  modalHeight?: string;
  modalWidth?: string;
  label?: string;
}

const ResponsiveModal: FunctionComponent<IResponsiveModalProps> = ({ children, modalHeight, modalWidth, label }) => {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const modalDimensions = !isMobile ? { width: modalWidth ?? 'auto', height: modalHeight ?? 'auto' } : {};

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Button onClick={handleOpen} variant={'contained'} color={'secondary'}>
        {label ?? t('modal.details')}
      </Button>

      <Dialog
        maxWidth="lg"
        fullScreen={isMobile}
        open={open}
        onClose={(_event, reason) => {
          if (reason === 'backdropClick') {
            return false;
          }
        }}
      >
        <DialogActions sx={{ padding: 0, position: 'absolute', top: 0, right: 0 }}>
          <IconButton
            sx={{
              backgroundColor: ColorPalette.monochrome.grey80,
              borderRadius: 0,
              color: ColorPalette.monochrome.white,
              fontSize: 40,
              height: 40,
              width: 40,
              '&:hover': {
                backgroundColor: ColorPalette.primary.light,
              },
            }}
            onClick={handleClose}
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        </DialogActions>
        <DialogContent sx={{ padding: 4, maxWidth: 'inherit' }} style={modalDimensions}>
          {children}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ResponsiveModal;
