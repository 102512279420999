import React, { FunctionComponent } from 'react';
import { Box, Step, StepLabel, Stepper } from '@mui/material';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Finished from './Finished';

import { useTranslation } from 'react-i18next';
import { ISurveyResponse } from '@shared/interfaces';

interface IMappingStepperProps {
  onAfterCreation: () => void;
  surveyList: ISurveyResponse[];
  groups: string[];
}

const MappingStepper: FunctionComponent<IMappingStepperProps> = ({ onAfterCreation, surveyList, groups }) => {
  const { t } = useTranslation();

  const [activeStep, setActiveStep] = React.useState(0);
  const [formValues, setFormValues] = React.useState({
    bookingNumbers: [],
    formatNumbers: [],
  });

  const steps = [
    t('pages.mappingSurvey.steps.step1.title'),
    t('pages.mappingSurvey.steps.step2.title'),
    t('pages.mappingSurvey.steps.step3.title'),
  ];

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return <Step1 {...formValues} groups={groups} onNext={handleNext} />;
      case 1:
        return <Step2 {...formValues} onNext={handleNext} onBack={handleBack} surveyList={surveyList} />;
      case 2:
        return <Step3 {...formValues} onNext={handleNext} onBack={handleBack} onAfterCreation={onAfterCreation} />;
      default:
        return <></>;
    }
  };

  const handleNext = (newValues: Record<string, unknown>) => {
    setFormValues({ ...formValues, ...newValues });
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = (newValues: Record<string, unknown>) => {
    setFormValues({ ...formValues, ...newValues });
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <>
      <Stepper activeStep={activeStep}>
        {steps.map((label) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: { optional?: React.ReactNode } = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      <div>{activeStep === steps.length ? <Finished {...formValues} /> : <Box>{getStepContent(activeStep)}</Box>}</div>
    </>
  );
};

export default MappingStepper;
