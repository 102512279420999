import { useEffect, useState, FunctionComponent, useContext } from 'react';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  createFilterOptions,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  CircularProgress,
  IconButton,
  Select,
  MenuItem,
  Paper,
  FormControlLabel,
  Switch,
  TextField,
  Card,
  CardContent,
  Theme,
  Snackbar,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import {
  IQuestionMappingResponse,
  ISurveyQuestionResponse,
  ISurveyResponse,
  ITechnicalQuestionIdResponse,
} from '@shared/interfaces';
import MicroFrontendContext from '../../components/MicroFrontendContext';
import { getApiService } from '../../api/api-request';

const MappingQuestionsPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const [surveyListLoading, setSurveyListLoading] = useState(false);
  const [questionListLoading, setQuestionListLoading] = useState<boolean>(false);
  const [questionMappings, setQuestionMappings] = useState<Record<string, IQuestionMappingResponse>>({});
  const [surveyList, setSurveyList] = useState<ISurveyResponse[]>([]);
  const [selectedSurvey, setSelectedSurvey] = useState<ISurveyResponse>();
  const [surveyQuestions, setSurveyQuestions] = useState<ISurveyQuestionResponse[]>([]);
  const [editQuestionId, setEditQuestionId] = useState('');
  const [selectedTechnicalQuestion, setSelectedTechnicalQuestion] = useState<ITechnicalQuestionIdResponse | null>();
  const [selectedScope, setSelectedScope] = useState('');
  const [savingMapping, setSavingMapping] = useState(false);
  const [showDeletedQuestions, setShowDeletedQuestions] = useState(false);
  const [showSaveFeedback, setShowSaveFeedback] = useState(false);
  const [saveMappingError, setSaveMappingError] = useState('');
  const [technicalQuestionIds, setTechnicalQuestionIds] = useState<ITechnicalQuestionIdResponse[]>([]);
  const [technicalQuestionIdsHash, setTechnicalQuestionIdsHash] =
    useState<Record<string, ITechnicalQuestionIdResponse>>();

  const { getJWT } = useContext(MicroFrontendContext);

  const useStyles = makeStyles((theme: Theme) => ({
    questionRowReadOnly: {
      backgroundColor: theme.palette.grey[300],
    },
    tableRow: {
      height: '75px',
      '& td': {
        height: 'auto !important',
      },
    },
    technicalQuestionId: {
      fontSize: '9px',
      color: theme.palette.grey[500],
    },
    technicalQuestionIdLabel: {
      display: 'flex',
      flexDirection: 'column',
      borderBottom: '1px solid #EEE',
      padding: '10px',
      fontSize: '14px',
      alignItems: 'flex-start',
    },
  }));

  const initialize = async () => {
    setSurveyListLoading(true);
    try {
      const apiService = getApiService(getJWT);
      const surveyListResponse = await apiService.getSurveysData();
      const technicalQuestionIdsResponse = await apiService.getTechnicalQuestionIds();
      setSurveyList(surveyListResponse);
      setTechnicalQuestionIds(technicalQuestionIdsResponse);
      setTechnicalQuestionIdsHash(
        technicalQuestionIdsResponse.reduce(
          (acc: Record<string, ITechnicalQuestionIdResponse>, curr: ITechnicalQuestionIdResponse) => {
            return {
              ...acc,
              [curr.id]: curr,
            };
          },
          {}
        )
      );
    } catch (error) {
      // TODO: show error message
    } finally {
      setSurveyListLoading(false);
    }
  };

  interface IEditableCellProps {
    editMode: boolean;
    questionId: string;
    value: string | undefined;
  }

  const TechnicalQuestionIdCell = (props: IEditableCellProps) => {
    return props.editMode ? (
      <Autocomplete
        id="technical-question-id-select"
        options={technicalQuestionIds}
        renderOption={(props, option: ITechnicalQuestionIdResponse) => {
          return (
            <li {...props} key={option.id} className={classes.technicalQuestionIdLabel}>
              <div>{option?.labels.de ?? ''}</div>
              <div className={classes.technicalQuestionId}>{option.id}</div>
            </li>
          );
        }}
        getOptionLabel={(option: ITechnicalQuestionIdResponse) => option.labels.de || ''}
        renderInput={(params: AutocompleteRenderInputParams) => <TextField {...params} variant="outlined" />}
        onChange={(_event, value) => setSelectedTechnicalQuestion(value)}
        value={selectedTechnicalQuestion}
        filterOptions={createFilterOptions({
          stringify: ({ id, labels }) => `${id} ${labels.de}`,
        })}
        size="small"
      />
    ) : (
      <>
        {props.value && technicalQuestionIdsHash?.[props.value]?.labels.de}
        <br />
        <span className={classes.technicalQuestionId}>{props.value}</span>
      </>
    );
  };

  const ScopeCell = (props: IEditableCellProps) => {
    return props.editMode ? (
      <Select
        labelId="scope-select-label"
        id="scope-select"
        value={selectedScope || props.value}
        displayEmpty
        onChange={(event) => setSelectedScope(`${event.target.value}`)}
      >
        <MenuItem key="scope-no-scope" value={undefined}>
          {t('pages.mappingQuestions.scopes.noScope')}
        </MenuItem>
        <MenuItem key="scope-no-arbeitsrecht" value="ARBEITSRECHT">
          {t('pages.mappingQuestions.scopes.arbeitsrecht')}
        </MenuItem>
        <MenuItem key="scope-no-lohnsteuer" value="LOHNSTEUER">
          {t('pages.mappingQuestions.scopes.lohnsteuer')}
        </MenuItem>
        <MenuItem key="scope-no-sozialversicherung" value="SOZIALVERSICHERUNG">
          {t('pages.mappingQuestions.scopes.sozialversicherung')}
        </MenuItem>
        <MenuItem key="scope-no-personalstrategie" value="PERSONALSTRATEGIE">
          {t('pages.mappingQuestions.scopes.personalstrategie')}
        </MenuItem>
        <MenuItem key="scope-no-trainer1" value="TRAINER1">
          {t('pages.mappingQuestions.scopes.trainer1')}
        </MenuItem>
        <MenuItem key="scope-no-trainer2" value="TRAINER2">
          {t('pages.mappingQuestions.scopes.trainer2')}
        </MenuItem>
        <MenuItem key="scope-no-trainer3" value="TRAINER3">
          {t('pages.mappingQuestions.scopes.trainer3')}
        </MenuItem>
        <MenuItem key="scope-no-trainer4" value="TRAINER4">
          {t('pages.mappingQuestions.scopes.trainer4')}
        </MenuItem>
        <MenuItem key="scope-no-trainer5" value="TRAINER5">
          {t('pages.mappingQuestions.scopes.trainer5')}
        </MenuItem>
      </Select>
    ) : (
      <>{props.value}</>
    );
  };

  const getSurveyQuestionList = async (surveyId: string) => {
    setQuestionListLoading(true);
    try {
      const apiService = getApiService(getJWT);
      const surveyQuestionResponse = await apiService.getSurveyQuestions(surveyId);
      const questionMappingsResponse = await apiService.getQuestionMappings(surveyId);
      setSurveyQuestions(surveyQuestionResponse);
      setQuestionMappings(questionMappingsResponse);
    } catch (error) {
      // TODO: show error
    } finally {
      setQuestionListLoading(false);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    if (selectedSurvey) {
      getSurveyQuestionList(selectedSurvey.surveyId);
    }
  }, [selectedSurvey]);

  const handleEditClick = (questionId: string) => {
    setEditQuestionId(questionId);
    setSelectedTechnicalQuestion(technicalQuestionIdsHash?.[questionMappings[questionId]?.technicalQuestionId]);
  };

  const handleCancelClick = (_questionId: string) => {
    setEditQuestionId('');
  };

  const handleSaveClick = async (questionId: string) => {
    setSavingMapping(true);
    try {
      const apiService = getApiService(getJWT);
      const questionMapping = await apiService.saveQuestionMapping(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        selectedSurvey!.surveyId,
        questionId,
        selectedTechnicalQuestion?.id,
        selectedScope
      );
      setQuestionMappings((currentQuestionMappings) => {
        return {
          ...currentQuestionMappings,
          [questionMapping.questionId]: questionMapping,
        };
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setSaveMappingError(error.message);
    } finally {
      setSelectedScope('');
      setSelectedTechnicalQuestion(undefined);
      setEditQuestionId('');
      setSavingMapping(false);
      // setShowSaveFeedback(true)
    }
  };

  const classes = useStyles();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSaveFeedbackClose = (_event?: any, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowSaveFeedback(false);
    setSaveMappingError('');
  };

  return (
    <Grid container spacing={2}>
      <Snackbar open={showSaveFeedback} autoHideDuration={3000} onClose={handleSaveFeedbackClose}>
        {saveMappingError.length > 0 ? (
          <Alert onClose={handleSaveFeedbackClose} severity="error">
            {t('pages.mappingQuestions.saveMappingErrorMessage')}: {saveMappingError}
          </Alert>
        ) : (
          <Alert onClose={handleSaveFeedbackClose} severity="success">
            {t('pages.mappingQuestions.saveMappingSuccessMessage')}
          </Alert>
        )}
      </Snackbar>
      <Grid item xs={12}>
        <Paper>
          <Card>
            <CardContent>
              <Autocomplete
                id="survey-autocomplete"
                options={surveyList}
                getOptionLabel={(option: ISurveyResponse) => option.surveyTitle || ''}
                style={{ width: 600 }}
                renderInput={(params: AutocompleteRenderInputParams) => (
                  <TextField {...params} label={t('pages.mappingQuestions.surveyListLabel')} variant="outlined" />
                )}
                onChange={(_event, value) => setSelectedSurvey(value ?? undefined)}
                loading={surveyListLoading}
                loadingText={t('pages.mappingQuestions.loadingSurveys')}
              />
            </CardContent>
          </Card>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        {selectedSurvey ? (
          <>
            {questionListLoading ? (
              <>
                <CircularProgress />
              </>
            ) : (
              <>
                <>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={showDeletedQuestions}
                        onChange={(event) => setShowDeletedQuestions(event.target.checked)}
                        name="show-deleted-questions"
                        color="primary"
                      />
                    }
                    label={t('pages.mappingQuestions.showDeletedQuestions')}
                  />
                </>
                <TableContainer component={Paper}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell>{t('pages.mappingQuestions.tableHeaders.question')}</TableCell>
                        <TableCell width={300}>
                          {t('pages.mappingQuestions.tableHeaders.technicalQuestionId')}
                        </TableCell>
                        <TableCell width={200}>{t('pages.mappingQuestions.tableHeaders.scope')}</TableCell>
                        <TableCell>{t('pages.mappingQuestions.tableHeaders.actions')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {surveyQuestions
                        .filter((item) => showDeletedQuestions || item.active)
                        .map((row) => (
                          <TableRow
                            key={row.id}
                            className={row.mappable ? classes.tableRow : classes.questionRowReadOnly}
                          >
                            <TableCell>{row.text}</TableCell>
                            <TableCell>
                              <TechnicalQuestionIdCell
                                questionId={row.id}
                                editMode={row.id === editQuestionId}
                                value={questionMappings[row.id]?.technicalQuestionId}
                              />
                            </TableCell>
                            <TableCell>
                              <ScopeCell
                                questionId={row.id}
                                editMode={row.id === editQuestionId}
                                value={questionMappings[row.id]?.scope}
                              />
                            </TableCell>

                            {row.mappable ? (
                              <TableCell>
                                {row.id === editQuestionId ? (
                                  <>
                                    {savingMapping ? (
                                      <CircularProgress size={20} />
                                    ) : (
                                      <>
                                        <IconButton
                                          color="inherit"
                                          size="small"
                                          aria-label="edit"
                                          onClick={() => handleSaveClick(row.id)}
                                          disabled={!selectedTechnicalQuestion}
                                        >
                                          <SaveIcon fontSize="small" />
                                        </IconButton>
                                        <IconButton
                                          color="inherit"
                                          size="small"
                                          aria-label="edit"
                                          onClick={() => handleCancelClick(row.id)}
                                        >
                                          <CancelIcon fontSize="small" />
                                        </IconButton>
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <IconButton
                                    color="inherit"
                                    size="small"
                                    aria-label="edit"
                                    onClick={() => handleEditClick(row.id)}
                                  >
                                    <EditIcon fontSize="small" />
                                  </IconButton>
                                )}
                              </TableCell>
                            ) : (
                              <TableCell></TableCell>
                            )}
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </>
        ) : (
          <>{t('pages.mappingQuestions.selectSurveyHint')}</>
        )}
      </Grid>
    </Grid>
  );
};

export default MappingQuestionsPage;
