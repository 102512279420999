import {
  Box,
  Chip,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEvent, FunctionComponent, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MicroFrontendContext from '../../components/MicroFrontendContext';
import { useSurveyMappingsQuery } from '../../queries/survey-mappings';
import { useSurveysQuery } from '../../queries/surveys';

interface IMappingInhousePageProps {
  businessUnit: 'IHC' | 'ILS';
}

const MappingInhousePage: FunctionComponent<IMappingInhousePageProps> = ({ businessUnit }) => {
  const { t } = useTranslation();

  const [eventId, setEventId] = useState<string | null>(null);
  const [selectedEventId, setSelectedEventId] = useState<string | null>(null);

  const { getJWT } = useContext(MicroFrontendContext);

  const { data: surveys } = useSurveysQuery(getJWT);
  const { data: surveyMappings, isLoading: surveyMappingsLoading } = useSurveyMappingsQuery(
    getJWT,
    'EVENT_ID',
    selectedEventId ?? undefined,
    businessUnit
  );

  const handleEventIdChanged = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newEventId = e.target.value;
    setEventId(newEventId);
    if (newEventId.length === 8) {
      setSelectedEventId(newEventId);
    }
  };

  return (
    <Box mt={2}>
      <Typography variant="h1" component="h2" className="m-4">
        {t('pages.surveyMappingInhouse.title', { businessUnit })}
      </Typography>

      <TextField inputProps={{ maxLength: 8 }} onChange={handleEventIdChanged} value={eventId} variant="outlined" />

      {surveyMappingsLoading && <LinearProgress />}

      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>{t('pages.mappingSurvey.table.headers.active')}</TableCell>
              <TableCell>{t('pages.mappingSurvey.table.headers.surveyType')}</TableCell>
              <TableCell>{t('pages.mappingSurvey.table.headers.surveyTitle')}</TableCell>
              <TableCell>{t('pages.mappingSurvey.table.headers.surveyDistributionType')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {surveyMappings?.result
              .filter((item) => item.mappingType === businessUnit)
              .map((surveyMapping) => (
                <TableRow>
                  <TableCell component="th" scope="row">
                    {surveyMapping.id}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {surveyMapping.excludedEvents.includes(surveyMapping.id) ? (
                      <Chip color="warning" label="deaktiviert" />
                    ) : (
                      <Chip color="success" label="aktiv" />
                    )}
                  </TableCell>
                  <TableCell>{t(`pages.mappingSurvey.surveyTypes.${surveyMapping.surveyType}`)}</TableCell>
                  <TableCell>
                    {surveys?.find((survey) => survey.surveyId === surveyMapping.surveyId)?.surveyTitle}
                  </TableCell>
                  <TableCell>
                    {surveyMapping.distributionTypes && surveyMapping.distributionTypes.length > 0
                      ? surveyMapping.distributionTypes
                          ?.map((distribution) => t(`pages.mappingSurvey.surveyDistributionTypes.${distribution}`))
                          .join(', ')
                      : t('pages.mappingSurvey.surveyDistributionTypes.NONE')}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default MappingInhousePage;
