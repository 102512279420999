import { ReactNode } from 'react';
import { StylesProvider, createGenerateClassName } from '@mui/styles';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { LightHouseTheme } from '@packages/lighthouse-theme';

interface IThemeWrapperProps {
  children: ReactNode;
}

const generateClassName = createGenerateClassName({
  productionPrefix: 'organizations',
  seed: 'organizations',
});

const LightHouseThemeProvider = ({ children }: IThemeWrapperProps) => {
  return (
    <StylesProvider generateClassName={generateClassName} injectFirst>
      <ThemeProvider key="organizations-theme" theme={LightHouseTheme}>
        <CssBaseline>{children}</CssBaseline>
      </ThemeProvider>
    </StylesProvider>
  );
};

export default LightHouseThemeProvider;
