import { FunctionComponent, useContext, useState } from 'react';
import { Box, Theme, FormControl, Button, LinearProgress } from '@mui/material';
import { prepareNewMappingData } from '../../utils/newMapping';
import { useTranslation } from 'react-i18next';
import { IProductResponse, ISurveyResponse } from '@shared/interfaces';
import { makeStyles } from '@mui/styles';
import MicroFrontendContext from '../../components/MicroFrontendContext';
import { getApiService } from '../../api/api-request';

const useStyles = makeStyles((theme: Theme) => ({
  formMappings: {
    '& .MuiFormControl-root': {
      marginBottom: theme.spacing(2),
      display: 'block',
    },
    '& .MuiInputBase-root': {
      width: '100%',
      maxWidth: '500px',
    },
    '& .MuiButtonBase-root + *': {
      margin: theme.spacing(1),
    },
  },
}));

interface IStep3Props {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  onNext: (...args: any) => void;
  onBack: (...args: any) => void;
  onAfterCreation: (...args: any) => void;
  /* eslint-enable @typescript-eslint/no-explicit-any */
  products?: IProductResponse[];
  formatNumbers?: string[];
  survey?: ISurveyResponse;
  surveyDistributionsType?: string[];
  surveyType?: string;
}

const Step3: FunctionComponent<IStep3Props> = ({
  onNext,
  onBack,
  onAfterCreation,
  products,
  formatNumbers,
  survey,
  surveyDistributionsType,
  surveyType,
}) => {
  const { t } = useTranslation();
  const { getJWT } = useContext(MicroFrontendContext);

  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async () => {
    if ((products || formatNumbers) && survey && surveyType) {
      setLoading(true);
      const apiService = getApiService(getJWT);
      const createNewMapping = await apiService.addSurveyMapping(
        prepareNewMappingData(
          products ?? [],
          formatNumbers ?? [],
          survey,
          surveyType,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          surveyDistributionsType!,
          'KFF'
        )
      );
      setLoading(false);
      if (createNewMapping) {
        onAfterCreation();
        onNext({ result: createNewMapping });
      }
    }
  };

  return (
    <Box component="div" pt={3} className={classes.formMappings}>
      {loading ? (
        <LinearProgress />
      ) : (
        <>
          <FormControl>
            <strong>
              {products && products.length > 0
                ? t('pages.mappingSurvey.steps.step3.selectedProducts')
                : t('pages.mappingSurvey.steps.step3.selectedFormats')}
            </strong>
            {products && products.length > 0
              ? products?.map((e) => (
                  <div key={e.bnr}>
                    {e.bnr}: {e.eventTitle}
                  </div>
                ))
              : formatNumbers?.map((e) => <div key={e}>{e}</div>)}
          </FormControl>
          <FormControl>
            <strong>{t('pages.mappingSurvey.steps.step3.selectedSurvey')}:</strong>
            <div>{survey?.surveyTitle}</div>
          </FormControl>
          <FormControl>
            <strong>{t('pages.mappingSurvey.steps.step3.selectedSurveyType')}:</strong>
            <div>{t(`pages.mappingSurvey.surveyTypes.${surveyType}`)}</div>
          </FormControl>
          <FormControl>
            <strong>{t('pages.mappingSurvey.steps.step3.selectedSurveyDistributionsType')}:</strong>
            <div>
              {surveyDistributionsType && surveyDistributionsType.length > 0
                ? surveyDistributionsType
                    ?.map((distribution) => t(`pages.mappingSurvey.surveyDistributionTypes.${distribution}`))
                    .join(', ')
                : t('pages.mappingSurvey.surveyDistributionTypes.NONE')}
            </div>
          </FormControl>
          <FormControl>
            <Button variant="outlined" onClick={onBack}>
              {t('pages.mappingSurvey.steps.step3.btnBackLabel')}
            </Button>
            <Button variant="contained" color="primary" component="button" onClick={handleSubmit}>
              {t('pages.mappingSurvey.steps.step3.btnLabel')}
            </Button>
          </FormControl>
        </>
      )}
    </Box>
  );
};

export default Step3;
