import { useQuery } from '@tanstack/react-query';
import { getApiService } from '../api/api-request';

const ROOT_KEY = 'survey-mappings';

const surveyMappingKeys = {
  surveyMappings: (idType: string | undefined, id: string | undefined, mappingType: string | undefined) =>
    [ROOT_KEY, idType, id, mappingType] as const,
};

export const useSurveyMappingsQuery = (
  getJWT: () => Promise<string | undefined | null>,
  idType: string | undefined,
  id: string | undefined,
  mappingType: string | undefined
) => {
  return useQuery({
    queryKey: surveyMappingKeys.surveyMappings(idType, id, mappingType),
    queryFn: async () => await getApiService(getJWT).getSurveyMappings(idType, id, mappingType),
    gcTime: Infinity,
  });
};
