import React, { Dispatch, FunctionComponent, SetStateAction, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ISurveyLinkResponse } from '@shared/interfaces';
import { IconButton, ListItemIcon, Menu, MenuItem } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CopyToClipboard from 'react-copy-to-clipboard';
import { ALLOWED_GROUPS, hasRequiredGroup } from '@hmg-aka-lu/cockpit-frontend/dist-types/exports';
import MicroFrontendContext from '../../components/MicroFrontendContext';

interface IContextMenuProps {
  id: string;
  row: ISurveyLinkResponse;
  qrCodeData: string;
  setCopied: Dispatch<SetStateAction<boolean>>;
  setSelectedSurveyLink: Dispatch<SetStateAction<ISurveyLinkResponse | null>>;
  setShowEditDialog: Dispatch<SetStateAction<boolean>>;
}

const ContextMenu: FunctionComponent<IContextMenuProps> = ({
  qrCodeData,
  row,
  setCopied,
  id,
  setSelectedSurveyLink,
  setShowEditDialog,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { groups } = useContext(MicroFrontendContext);

  const handleClickContextMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseContextMenu = () => {
    setAnchorEl(null);
  };

  const downloadQrCode = (content: string, filename: string) => {
    const link = document.createElement('a');
    link.href = content;
    link.setAttribute('download', filename);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode?.removeChild(link);
  };

  return (
    <>
      <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClickContextMenu}>
        <MoreVertIcon />
      </IconButton>
      <Menu id={id} anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleCloseContextMenu}>
        <MenuItem>
          <CopyToClipboard
            text={row.url}
            onCopy={() => {
              setCopied(true);
              handleCloseContextMenu();
            }}
          >
            <span>
              <ListItemIcon>
                <FileCopyOutlinedIcon fontSize="small" color="action" />
              </ListItemIcon>
              {t('pages.fbLinks.table.actions.copyLink')}
            </span>
          </CopyToClipboard>
        </MenuItem>
        <MenuItem
          onClick={() => {
            downloadQrCode(qrCodeData, `${row.eventId}-qr-code`);
            handleCloseContextMenu();
          }}
        >
          <ListItemIcon>
            <SaveAltOutlinedIcon fontSize="small" color="action" />
          </ListItemIcon>
          {t('pages.fbLinks.table.actions.downloadQrCode')}
        </MenuItem>
        {hasRequiredGroup([ALLOWED_GROUPS.SURVEY_LINKS_WRITE], groups) && (
          <MenuItem
            onClick={() => {
              setSelectedSurveyLink(row);
              setShowEditDialog(true);
              handleCloseContextMenu();
            }}
          >
            <ListItemIcon>
              <EditIcon fontSize="small" color="action" />
            </ListItemIcon>
            {t('pages.fbLinks.table.actions.extendValidity')}
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default ContextMenu;
