import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  LinearProgress,
  Tooltip,
  TextField,
  Theme,
} from '@mui/material';
import MappingStepper from './MappingStepper';
import ResponsiveModal from '../../components/ResponsiveModal/ResponsiveModal';
import { useTranslation } from 'react-i18next';
import DialogConfirmation from '../../components/DialogConfirmation/DialogConfirmation';
import DeleteIcon from '@mui/icons-material/Delete';
import DOMPurify from 'dompurify';
import { ISurveyMappingResponse, ISurveyResponse } from '@shared/interfaces';
import { makeStyles } from '@mui/styles';
import MicroFrontendContext from '../../components/MicroFrontendContext';
import { getApiService } from '../../api/api-request';
import { ALLOWED_GROUPS, hasRequiredGroup } from '@hmg-aka-lu/cockpit-frontend/dist-types/exports';

const useStyles = makeStyles((theme: Theme) => ({
  tableHeader: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      '& button, & .MuiFormControl-root, & .MuiInputBase-root': {
        width: '100%',
      },
      '& button': {
        marginBottom: theme.spacing(1),
      },
    },
  },
}));

const MappingSurveyPage: FunctionComponent = () => {
  const [mappingSurveyList, setMappingSurveyList] = useState<ISurveyMappingResponse[]>();
  const [filteredMappingSurveyList, setFilteredMappingSurveyList] = useState<ISurveyMappingResponse[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [surveyList, setSurveyList] = useState<ISurveyResponse[]>([]);
  const [filterTerm, setFilterTerm] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  const rowsPerPage = 10;

  const { t } = useTranslation();
  const classes = useStyles();

  const { getJWT, groups } = useContext(MicroFrontendContext);

  const getList = async () => {
    setLoading(true);
    const apiService = getApiService(getJWT);
    const mappingsList = await apiService.getSurveyMappings(undefined, undefined, 'KFF');
    setMappingSurveyList(mappingsList.result);
    setLoading(false);
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const getSurveyList = async () => {
    const apiService = getApiService(getJWT);
    const surveyListResponse = await apiService.getSurveysData();
    setSurveyList(surveyListResponse);
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterTerm(event.target.value);
  };

  useEffect(() => {
    getList();
    getSurveyList();
  }, []);

  useEffect(() => {
    const applyFilter = (row: ISurveyMappingResponse): boolean =>
      row.id.includes(filterTerm) ||
      row.idType.includes(filterTerm) ||
      row.eventTitle.includes(filterTerm) ||
      row.surveyTitle.includes(filterTerm) ||
      t(`pages.mappingSurvey.surveyTypes.${row.surveyType}`).includes(filterTerm);
    setPage(0);
    setFilteredMappingSurveyList(mappingSurveyList?.filter((row) => applyFilter(row)));
  }, [filterTerm, mappingSurveyList, t]);

  const handleDelete = async (idType: string, id: string, surveyType: string) => {
    const apiService = getApiService(getJWT);
    await apiService.deleteSurveyMapping(idType, id, surveyType);
    getList();
  };

  return (
    <>
      <Box mt={2}>
        <Typography variant="h1" component="h2" className="m-4">
          {t('pages.mappingSurvey.title')}
        </Typography>

        <Box
          display="flex"
          justifyContent="space-between"
          mb={3}
          alignItems={'flex-end'}
          className={classes.tableHeader}
        >
          <TextField id="mapping-filter" label="Filter" onChange={handleFilterChange} size="small" margin="dense" />
          {hasRequiredGroup([ALLOWED_GROUPS.SURVEY_MAPPINGS_WRITE], groups) && (
            <ResponsiveModal
              children={<MappingStepper onAfterCreation={getList} surveyList={surveyList} groups={groups} />}
              label={t('pages.mappingSurvey.createMapping')}
            />
          )}
        </Box>
        {loading && <LinearProgress />}
        {!loading && !mappingSurveyList && (
          <Alert severity="warning">{t('pages.mappingSurvey.mappingsNotFound')}</Alert>
        )}
        {mappingSurveyList && (
          <>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>{t('pages.mappingSurvey.table.headers.bookingNumber')}</TableCell>
                    <TableCell>{t('pages.mappingSurvey.table.headers.productTitle')}</TableCell>
                    <TableCell>{t('pages.mappingSurvey.table.headers.surveyType')}</TableCell>
                    <TableCell>{t('pages.mappingSurvey.table.headers.surveyTitle')}</TableCell>
                    <TableCell>{t('pages.mappingSurvey.table.headers.surveyDistributionType')}</TableCell>
                    <TableCell>{t('pages.mappingSurvey.table.headers.actions')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredMappingSurveyList
                    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {t(`pages.mappingSurvey.idTypes.${row.idType}`)}: {row.id}
                        </TableCell>
                        <TableCell>{row.eventTitle}</TableCell>
                        <TableCell>{t(`pages.mappingSurvey.surveyTypes.${row.surveyType}`)}</TableCell>
                        <TableCell>
                          <Tooltip title="Feedbackbogen nicht abschicken, nur zum Testen" placement={'top'} arrow>
                            <a href={DOMPurify.sanitize(row.surveyUrl)} target="_blank" rel="noreferrer">
                              {row.surveyTitle}
                            </a>
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          {row.distributionTypes && row.distributionTypes.length > 0
                            ? row.distributionTypes
                                ?.map((distribution) =>
                                  t(`pages.mappingSurvey.surveyDistributionTypes.${distribution}`)
                                )
                                .join(', ')
                            : t('pages.mappingSurvey.surveyDistributionTypes.NONE')}
                        </TableCell>
                        <TableCell>
                          {row.idType !== 'FORMAT' &&
                            hasRequiredGroup([ALLOWED_GROUPS.SURVEY_MAPPINGS_WRITE], groups) && (
                              <DialogConfirmation
                                confirmCallback={() => handleDelete(row.idType, row.id, row.surveyType)}
                                confirmText={t('common.delete')}
                                cancelText={t('common.cancel')}
                                dialogTitle={t('common.reallyDelete')}
                              >
                                <DeleteIcon />
                              </DialogConfirmation>
                            )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={filteredMappingSurveyList?.length ?? 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
            />
          </>
        )}
      </Box>
    </>
  );
};

export default MappingSurveyPage;
