import {
  Alert,
  AlertColor,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  LinearProgress,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import format from 'date-fns/format';
import { parseISO } from 'date-fns';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { toDataURL } from 'qrcode';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import { CSVLink } from 'react-csv';
import EditSurveyLinkDialog from './EditSurveyLinkDialog';
import InfoIcon from '@mui/icons-material/Info';
import ContextMenu from './ContextMenu';
import DOMPurify from 'dompurify';
import { ISurveyLinkResponse } from '@shared/interfaces';
import MicroFrontendContext from '../../components/MicroFrontendContext';
import { ISurveyLinkErrorResponse } from '@shared/interfaces/src/survey-link';
import { getApiService } from '../../api/api-request';
import { useParams } from 'react-router-dom';

const FBLinkQueryPage: FunctionComponent = () => {
  const surveyTypes = ['PARTICIPANT_FEEDBACK', 'TRAINER_FEEDBACK', 'BETREUER_FEEDBACK', 'TECHNIK_SUPPORT_FEEDBACK'];

  const { t } = useTranslation();
  const { getJWT } = useContext(MicroFrontendContext);

  const { eventId: pathParamEventId } = useParams();
  const [vaId, setVaId] = useState(pathParamEventId ?? '');
  const [linksList, setLinksList] = useState<ISurveyLinkResponse[]>();
  const [errorLinks, setErrorLinks] = useState<ISurveyLinkErrorResponse[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [copied, setCopied] = useState<boolean>(false);

  const [qrCodes, setQrCodes] = useState<string[]>([]);
  const [selectedSurveyTypes, setSelectedSurveyTypes] = useState<string[]>(surveyTypes);
  const [selectedSurveyLink, setSelectedSurveyLink] = useState<ISurveyLinkResponse | null>(null);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [alertText, setAlertText] = useState('');
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>('success');
  const [alertOpen, setAlertOpen] = useState(false);

  const showAlert = (severity: AlertColor, message: string) => {
    setAlertSeverity(severity);
    setAlertText(message);
    setAlertOpen(true);
  };

  const onSaveCallback = (surveyLink: ISurveyLinkResponse, failed = false) => {
    if (!failed) {
      setLinksList(linksList?.map((link) => (link.url === surveyLink.url ? surveyLink : link)));
      showAlert('success', t('pages.fbLinks.alerts.linkUpdated'));
    } else {
      showAlert('error', t('pages.fbLinks.alerts.linkUpdateFailed'));
    }
  };

  const onEditDialogCloseCallback = () => {
    setSelectedSurveyLink(null);
  };

  const handleSurveyTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const result = event.target.checked
      ? [...selectedSurveyTypes, event.target.name]
      : selectedSurveyTypes.filter((item) => item !== event.target.name);
    setSelectedSurveyTypes(result);
  };

  const handleSubmit = async () => {
    if (!vaId) {
      return false;
    }

    try {
      setLoading(true);
      const vaIdsFormated = vaId.split(/[\s,]+/).join();
      const apiService = getApiService(getJWT);
      const result = await apiService.getSurveyLinks(vaIdsFormated);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const list = result.filter((link): link is ISurveyLinkResponse => !('error' in link));
      const errorlist = result.filter((link): link is ISurveyLinkErrorResponse => 'error' in link);

      setQrCodes(await Promise.all(list.map((link) => toDataURL(DOMPurify.sanitize(link.url), { width: 1200 }))));
      setLinksList(list);
      setErrorLinks(errorlist);
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (copied) {
      showAlert('success', t('pages.fbLinks.alerts.linkCopied'));
      setCopied(false);
    }
  }, [copied, t]);

  useEffect(() => {
    if (pathParamEventId) {
      handleSubmit();
    }
  }, [pathParamEventId]);

  const csvExportHeaders = ['VA-ID', 'Trainer-ID', 'Feedback-Link'];

  const filteredLinksList = linksList?.filter((item) => selectedSurveyTypes.includes(item.surveyType)) ?? [];

  return (
    <Box mt={2}>
      <Typography variant="h1" component="h2" className="m-4">
        {t('pages.fbLinks.title')}
      </Typography>

      <Box p={3} component={Paper}>
        <Box display={'flex'} alignItems={'baseline'} mb={linksList || loading || error ? 4 : 0}>
          <TextField
            id="standard-basic"
            helperText={t('pages.fbLinks.inputHint')}
            label="VA ID"
            style={{ maxWidth: 360, marginRight: '15px' }}
            size="small"
            margin="dense"
            variant="standard"
            onChange={(e) => setVaId(e.currentTarget.value)}
          />
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            {t('pages.fbLinks.search')}
          </Button>
        </Box>

        <Snackbar
          open={alertOpen}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          onClose={() => setAlertOpen(false)}
        >
          <Alert onClose={() => setAlertOpen(false)} severity={alertSeverity}>
            {alertText}
          </Alert>
        </Snackbar>

        {loading && <LinearProgress />}
        {error ? (
          <Alert severity="error">{t('pages.fbLinks.error')}</Alert>
        ) : (
          linksList &&
          (linksList.length > 0 ? (
            <>
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
                style={{ marginBottom: '1.5rem' }}
              >
                <Box>
                  {surveyTypes.map((surveyType) => (
                    <FormControlLabel
                      key={`feedback-type-control-${surveyType}`}
                      control={
                        <Checkbox
                          checked={selectedSurveyTypes.includes(surveyType)}
                          onChange={handleSurveyTypeChange}
                          name={surveyType}
                        />
                      }
                      label={t(`pages.mappingSurvey.surveyTypes.${surveyType}`)}
                    />
                  ))}
                </Box>
                <CSVLink
                  filename="feedback-links-export.csv"
                  style={{ textDecoration: 'none' }}
                  data={[
                    csvExportHeaders,
                    ...filteredLinksList.map((item) => [item.eventId, item.trainerId ?? '', item.url]),
                  ]}
                >
                  <Button variant="outlined" startIcon={<SaveAltOutlinedIcon />}>
                    {t('pages.fbLinks.table.actions.csvDownload')}
                  </Button>
                </CSVLink>
              </Box>

              <TableContainer style={{ padding: 0 }}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('pages.fbLinks.table.headers.eventId')}</TableCell>
                      <TableCell>{t('pages.fbLinks.table.headers.trainerId')}</TableCell>
                      <TableCell>{t('pages.fbLinks.table.headers.surveyType')}</TableCell>
                      <TableCell>{t('pages.fbLinks.table.headers.url')}</TableCell>
                      <TableCell>{t('pages.fbLinks.table.headers.validFrom')}</TableCell>
                      <TableCell>{t('pages.fbLinks.table.headers.validUntil')}</TableCell>
                      <TableCell>{t('pages.fbLinks.table.headers.surveyDistributionType')}</TableCell>
                      <TableCell>{t('pages.fbLinks.table.headers.actions')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredLinksList.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>{row.parentId}</TableCell>
                        <TableCell>{row.trainerId}</TableCell>
                        <TableCell>{t(`pages.mappingSurvey.surveyTypes.${row.surveyType}`)}</TableCell>
                        <TableCell>
                          <a href={DOMPurify.sanitize(row.url)} target="_blank" rel="noreferrer">
                            {DOMPurify.sanitize(row.url)}
                          </a>
                        </TableCell>
                        <TableCell>{format(parseISO(row.validFrom), t('formatDateWithTime'))}</TableCell>
                        <TableCell>
                          {format(parseISO(row.validTo), t('formatDateWithTime'))}
                          {row.validityExtensionInDays && row.validityExtensionInDays > 0 && (
                            <Tooltip
                              title={
                                <>
                                  {t('pages.fbLinks.table.extensionInfoTooltip', { days: row.validityExtensionInDays })}
                                </>
                              }
                            >
                              <InfoIcon
                                fontSize="small"
                                style={{ marginLeft: '.5rem', marginBottom: '-.25rem' }}
                                color="primary"
                              />
                            </Tooltip>
                          )}
                        </TableCell>
                        <TableCell>
                          {row.distributionTypes && row.distributionTypes.length > 0
                            ? row.distributionTypes
                                ?.map((distribution) =>
                                  t(`pages.mappingSurvey.surveyDistributionTypes.${distribution}`)
                                )
                                .join(', ')
                            : t('pages.mappingSurvey.surveyDistributionTypes.NONE')}
                        </TableCell>
                        <TableCell>
                          <ContextMenu
                            id={`context-menu-${index}`}
                            row={row}
                            qrCodeData={qrCodes[index]}
                            setCopied={setCopied}
                            setSelectedSurveyLink={setSelectedSurveyLink}
                            setShowEditDialog={setShowEditDialog}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {errorLinks &&
                errorLinks.length > 0 &&
                errorLinks.map((link) => (
                  <Box key={link.surveyType} marginTop={2} display={'flex'} alignItems={'center'}>
                    <InfoOutlinedIcon color={'secondary'} style={{ marginRight: 10 }} />{' '}
                    {t(`pages.mappingSurvey.surveyTypes.${link.surveyType}`) +
                      ': ' +
                      t(`pages.fbLinks.errorCode_${link.errorCode}`)}
                  </Box>
                ))}
            </>
          ) : (
            <Box display={'flex'} alignItems={'center'}>
              <InfoOutlinedIcon color={'secondary'} style={{ marginRight: 10 }} /> {t('pages.fbLinks.notFound')}
            </Box>
          ))
        )}
        <EditSurveyLinkDialog
          surveyLink={selectedSurveyLink}
          showDialog={showEditDialog}
          setShowDialog={setShowEditDialog}
          onSaveCallback={onSaveCallback}
          onCloseCallback={onEditDialogCloseCallback}
        />
      </Box>
    </Box>
  );
};

export default FBLinkQueryPage;
