import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { FunctionComponent } from 'react';
import ChartBox from '../ChartBox';

interface IChartTextProps {
  title: string;
  data: string[];
}

export const ChartText: FunctionComponent<IChartTextProps> = ({ title, data }) => {
  return (
    <ChartBox title={title}>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableBody>
            {data?.map((row, index) => (
              <TableRow key={`${index}-${row.slice(0, 5)}`}>
                <TableCell component="th" scope="row">
                  {row}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ChartBox>
  );
};
