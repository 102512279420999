import { ICreateSurveyMappingsRequestBody, IProductResponse, ISurveyResponse } from '@shared/interfaces';
import { SURVEY_ILS_TRAINER_ID } from '../config';

export const prepareNewMappingData = (
  products: IProductResponse[],
  formatNumbers: string[],
  survey: ISurveyResponse,
  surveyType: string,
  surveyDistributionsType: string[],
  mappingType: string,
  excludedEvents?: string[]
): ICreateSurveyMappingsRequestBody[] => {
  const mappings: ICreateSurveyMappingsRequestBody[] = [];
  const idType = formatNumbers.length > 0 ? 'FORMAT' : 'BOOKING_NUMBER';
  const mappingTmpl = {
    surveyId: survey.surveyId,
    surveyTool: survey.surveyTool,
    surveyType,
    idType,
    distributionTypes: surveyDistributionsType,
    mappingType,
    excludedEvents,
  };

  products.forEach((product) => {
    mappings.push({
      ...mappingTmpl,
      id: product.bnr,
    });

    // TRAINER_FEEDBACK automatically create for ILS
    if (mappingType === 'ILS') {
      mappings.push({
        id: product.bnr,
        idType,
        surveyId: SURVEY_ILS_TRAINER_ID,
        surveyTool: survey.surveyTool,
        surveyType: 'TRAINER_FEEDBACK',
        distributionTypes: surveyDistributionsType,
        mappingType,
      });
    }
  });

  formatNumbers.forEach((formatNumber) => {
    mappings.push({
      ...mappingTmpl,
      id: formatNumber,
    });
  });

  return mappings;
};
