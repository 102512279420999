import { Box, Button, TextField } from '@mui/material';
import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IFeedbackReportFormProps {
  handleSubmit: (eventId: string) => void;
}

export const FeedbackReportForm: FunctionComponent<IFeedbackReportFormProps> = ({ handleSubmit }) => {
  const [eventId, setEventId] = useState('');

  const { t } = useTranslation();

  return (
    <Box>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(eventId);
        }}
      >
        <TextField
          label={t('pages.feedbackReport.form.eventIdLabel')}
          onChange={(e) => setEventId(e.target.value)}
          size="small"
          value={eventId}
        />

        <Button type="submit" sx={{ ml: 3 }} disabled={eventId.length === 0} variant="contained">
          {t('pages.feedbackReport.form.submitButtonLabel')}
        </Button>
      </form>
    </Box>
  );
};
