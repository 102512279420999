import { FunctionComponent } from 'react';
import { Box, Theme } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { ISurveyMappingResponse } from '@shared/interfaces';

const useStyles = makeStyles((theme: Theme) => ({
  success: {
    color: theme.palette.success.main,
  },
}));

interface IResultAddMappingProps {
  result: ISurveyMappingResponse;
  isFailed?: boolean;
}

const ResultAddMapping: FunctionComponent<IResultAddMappingProps> = ({ result, isFailed }) => {
  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <Box display="flex" alignItems="center" pb={2}>
      {isFailed ? <ClearIcon color={'error'} /> : <CheckIcon className={classes.success} />}
      <Box display="flex" flexDirection="column" ml={1}>
        <div>
          <strong>{t('pages.mappingSurvey.steps.step2.product')}:</strong> {result.eventTitle} ({result.id})
        </div>
        <div>
          <strong>{t('pages.mappingSurvey.steps.step2.survey')}:</strong> {result.surveyTitle}
        </div>
        <div>
          <strong>{t('pages.mappingSurvey.steps.step3.selectedSurveyType')}:</strong>{' '}
          {t(`pages.mappingSurvey.surveyTypes.${result.surveyType}`)}
        </div>
        <div>
          <strong>{t('pages.mappingSurvey.steps.step3.selectedSurveyDistributionsType')}:</strong>{' '}
          {result.distributionTypes && result.distributionTypes.length > 0
            ? result.distributionTypes
                ?.map((distribution) => t(`pages.mappingSurvey.surveyDistributionTypes.${distribution}`))
                .join(', ')
            : t('pages.mappingSurvey.surveyDistributionTypes.NONE')}
        </div>
      </Box>
    </Box>
  );
};

export default ResultAddMapping;
