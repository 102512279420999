import { useQuery } from '@tanstack/react-query';
import { getApiService } from '../api/api-request';

const ROOT_KEY = 'feedback-reports';

const feedbackReportKeys = {
  report: (eventId: string | undefined) => [ROOT_KEY, eventId] as const,
};

export const useFeedbackReport = (eventId: string | undefined, getJWT: () => Promise<string | undefined | null>) => {
  return useQuery({
    queryKey: feedbackReportKeys.report(eventId),
    queryFn: async () => await getApiService(getJWT).getReportData(eventId),
    gcTime: Infinity,
    enabled: Boolean(eventId),
  });
};
