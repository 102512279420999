import { ISurveyDataResponse } from '@shared/interfaces';

export interface IProcessedData {
  [key: string]: IQuestion | IGroup;
}

export interface ILabels {
  de: string;
  en?: string;
}

export interface IQuestion {
  text: string;
  labels?: ILabels;
  type: string;
  order?: number;
  answers: string[];
}

export interface IGroup {
  text: string;
  labels?: ILabels;
  type: string;
  choices: string[];
  order?: number;
  questions: { [key: string]: IQuestion };
}

export const parseSurveyDataResponse = (surveyDataResult: ISurveyDataResponse[]): IProcessedData => {
  const processed: IProcessedData = {};

  surveyDataResult.forEach((item) => {
    item.questions.forEach((question) => {
      const { technicalId, group, answers, text, type, externalId } = question;
      const answerText = answers.map((answer) => answer.text);

      if (technicalId && answerText.length > 0) {
        const groupKey = group ? group.id : null;

        if (groupKey) {
          const uniTechnicalId = `${technicalId}-${externalId}-${question.group?.id}`;
          if (!processed[groupKey]) {
            processed[groupKey] = {
              text: group?.label ?? '',
              type,
              order: question.order,
              questions: {},
              choices: group?.choices,
            } as IGroup;
          }

          if (!(processed[groupKey] as IGroup).questions[uniTechnicalId]) {
            (processed[groupKey] as IGroup).questions[uniTechnicalId] = {
              text: question.text,
              labels: question.labels,
              type: question.type,
              order: question.order,
              answers: answerText,
            };
          } else {
            const acc = (processed[groupKey] as IGroup).questions[uniTechnicalId].answers;
            (processed[groupKey] as IGroup).questions[uniTechnicalId].answers = acc.concat(answerText);
          }
        } else {
          const uniTechnicalId = `${technicalId}-${externalId}`;
          if (!processed[uniTechnicalId]) {
            processed[uniTechnicalId] = {
              text,
              type,
              labels: question.labels,
              order: question.order,
              answers: answerText,
            } as IQuestion;
          } else {
            const acc = (processed[uniTechnicalId] as IQuestion).answers;
            (processed[uniTechnicalId] as IQuestion).answers = acc.concat(answerText);
          }
        }
      }
    });
  });

  const sorted: IProcessedData = {};
  const sortedKeys = Object.keys(processed).sort((a, b) => (processed[a].order ?? 0) - (processed[b].order ?? 0));
  sortedKeys.forEach((key) => {
    sorted[key] = processed[key];
  });

  return sorted;
};
