import { useQuery } from '@tanstack/react-query';
import { getApiService } from '../api/api-request';

const ROOT_KEY = 'surveys';

const surveyKeys = {
  surveys: () => [ROOT_KEY] as const,
};

export const useSurveysQuery = (getJWT: () => Promise<string | undefined | null>) => {
  return useQuery({
    queryKey: surveyKeys.surveys(),
    queryFn: async () => await getApiService(getJWT).getSurveysData(),
    gcTime: Infinity,
  });
};
