type EnvironmentSpecificConfig = {
  API_URL: string;
};

const dev: EnvironmentSpecificConfig = {
  API_URL: 'http://localhost:8000/dev',
};
const test: EnvironmentSpecificConfig = {
  API_URL: 'https://079661thdg-vpce-0aaa75251ef9131a2.execute-api.eu-central-1.amazonaws.com/test',
};

const prod: EnvironmentSpecificConfig = {
  // @TODO replace api id after prod deployment
  API_URL: 'https://11w8zlu3i9-vpce-0cc03badf80bdd296.execute-api.eu-central-1.amazonaws.com/prod',
};

const env = process.env.REACT_APP_STAGE ?? 'dev';

const mergedConfig = {
  ...(env === 'dev' && dev),
  ...(env === 'test' && test),
  ...(env === 'prod' && prod),
  STANDALONE: env === 'dev',
};

export const SURVEY_ILS_TRAINER_ID =
  env === 'prod' ? '83733d19-8296-ed11-aad1-0022486f47d4' : '078cfd52-8495-ed11-aad1-0022486f47d4';
export const IHC_SURVEY_MAPPING_EVENT_DATE = env === 'prod' ? undefined : '2020-01-01';
export const ILS_ONLINE_LOCATIONS = [
  61265876, 61270961, 61259765, 61273279, 61285737, 61285738, 61285739, 61272927, 61264991, 61272931, 61272930,
  61272928, 61265878,
];
export const SURVEY_LINK_ILS_DIGITAL_URL =
  env === 'prod' ? 'https://ecv.microsoft.com/kXJqjgRFfn' : 'https://ecv.microsoft.com/cQVVFTGGMT';
export const SURVEY_LINK_ILS_DIGITAL_TITLE = env === 'prod' ? 'Live: TN ILS Digital' : 'Test: TN ILS Präsenz';
export const SURVEY_LINK_ILS_ONSITE_URL =
  env === 'prod' ? 'https://ecv.microsoft.com/ICOhhj9djc' : 'https://ecv.microsoft.com/wdkyeovQv9';
export const SURVEY_LINK_ILS_ONSITE_TITLE = env === 'prod' ? 'Live: TN ILS Präsenz' : 'Test: TN ILS Präsenz';

export const INHOUSE_SURVEY_ID =
  env === 'prod' ? '1d21a825-7896-ed11-aad1-0022486f47d4' : 'cdadfbae-9e95-ed11-aad1-0022486f47d4';
export const INHOUSE_SURVEY_LINK =
  env === 'prod' ? 'https://ecv.microsoft.com/kXJqjgRFfn' : 'https://ecv.microsoft.com/cQVVFTGGM';
export const INHOUSE_SURVEY_TITLE = env === 'prod' ? 'Live: TN ILS Digital' : 'Test: TN ILS Digital';

export const MICROFRONTEND_ID = 'feedbackService';

export default mergedConfig;

export const DATE_FORMAT = 'dd.MM.yyyy HH:mm';

export const STAGE = env;
