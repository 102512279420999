import { FunctionComponent } from 'react';
import { Alert, Box, Theme } from '@mui/material';
import ResultAddMapping from './ResultAddMapping';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { ICreateSurveyMappingsResponse, ISurveyMappingResponse } from '@shared/interfaces';

const useStyles = makeStyles((theme: Theme) => ({
  formMappings: {
    '& .MuiFormControl-root': {
      marginBottom: theme.spacing(2),
      display: 'block',
    },
    '& .MuiInputBase-root': {
      width: '100%',
      maxWidth: '500px',
    },
  },
  root: {
    width: '100%',
  },
}));

interface IFinishedProps {
  bookingNumbers?: string[];
  result?: ICreateSurveyMappingsResponse;
}

const Finished: FunctionComponent<IFinishedProps> = ({ result }) => {
  const { t } = useTranslation();

  const classes = useStyles();

  const renderResult = (result: ICreateSurveyMappingsResponse) => {
    if (result.failedMappings.length > 0) {
      return (
        <Alert severity="error">
          {t('pages.mappingSurvey.steps.finished.alertFailed', { failedLength: result.failedMappings.length })}
        </Alert>
      );
    } else {
      return (
        <Alert severity="success">
          {t('pages.mappingSurvey.steps.finished.alertSuccess', { successLength: result.createdMappings.length })}
        </Alert>
      );
    }
  };

  return (
    <Box pt={3} className={classes.formMappings}>
      {result && (
        <>
          <Box mb={3}>{renderResult(result)}</Box>
          {result.createdMappings?.map((mapping: ISurveyMappingResponse, i: number) => (
            <ResultAddMapping key={i} result={mapping} />
          ))}
          {result.failedMappings?.map((mapping: ISurveyMappingResponse, i: number) => (
            <ResultAddMapping key={i} result={mapping} isFailed={true} />
          ))}
        </>
      )}
    </Box>
  );
};

export default Finished;
