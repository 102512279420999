import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { Box, Theme, TextField, FormControl, Button, LinearProgress } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { ALLOWED_GROUPS, hasRequiredGroup } from '@hmg-aka-lu/cockpit-frontend/dist-types/exports';
import MicroFrontendContext from '../../components/MicroFrontendContext';
import { getApiService } from '../../api/api-request';

const useStyles = makeStyles((theme: Theme) => ({
  formMappings: {
    '& .MuiFormControl-root': {
      marginBottom: theme.spacing(2),
      display: 'block',
    },
    '& .MuiInputBase-root': {
      width: '100%',
      maxWidth: '500px',
    },
    '& .MuiButtonBase-root + *': {
      margin: theme.spacing(1),
    },
  },
}));

interface IStep1Props {
  onNext: (formValues: Record<string, unknown>) => void;
  bookingNumbers: string[];
  formatNumbers: string[];
  groups: string[];
}

const Step1: FunctionComponent<IStep1Props> = ({ onNext, bookingNumbers, formatNumbers, groups }) => {
  const { t } = useTranslation();

  const { getJWT } = useContext(MicroFrontendContext);

  const [bookingNum, setBookingNum] = useState<string>();
  const [formatNum, setFormatNum] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setBookingNum(bookingNumbers.join(', '));
  }, [bookingNumbers]);

  useEffect(() => {
    setFormatNum(formatNumbers.join(', '));
  }, [formatNumbers]);

  const handleBookingNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBookingNum(event.target.value);
  };

  const handleFormatChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormatNum(event.target.value);
  };

  const handleSubmit = async () => {
    if (bookingNum) {
      const bookingNumbers = bookingNum.split(/[\s,]+/);
      setLoading(true);
      const apiService = getApiService(getJWT);
      const products = await apiService.getProductsData(bookingNumbers);
      setLoading(false);
      onNext({
        bookingNumbers,
        formatNumbers: [],
        products,
      });
    } else if (formatNum) {
      const formatNumbers = formatNum.split(/[\s,]+/);
      onNext({
        bookingNumbers: [],
        products: [],
        formatNumbers,
      });
    }
  };

  const classes = useStyles();
  return (
    <Box pt={3} className={classes.formMappings}>
      {loading ? (
        <LinearProgress />
      ) : (
        <>
          <TextField
            required
            id="standard-required"
            label={t('pages.mappingSurvey.steps.step1.inputLabel')}
            defaultValue={bookingNumbers?.join(', ')}
            helperText={t('pages.mappingSurvey.steps.step1.inputHint')}
            onChange={handleBookingNumberChange}
            size="small"
            margin="dense"
          />

          {hasRequiredGroup([ALLOWED_GROUPS.SUPERUSER], groups) && (
            <TextField
              required
              id="standard-required"
              label={t('pages.mappingSurvey.steps.step1.inputLabelFormat')}
              defaultValue={formatNumbers?.join(', ')}
              helperText={t('pages.mappingSurvey.steps.step1.inputHintFormat')}
              onChange={handleFormatChange}
              size="small"
              margin="dense"
            />
          )}
          <FormControl>
            <Button
              disabled={
                (bookingNum?.length === 0 && formatNum?.length === 0) ||
                (bookingNum?.length !== 0 && formatNum?.length !== 0)
              }
              variant="contained"
              color="primary"
              component="button"
              onClick={handleSubmit}
            >
              {t('pages.mappingSurvey.steps.step1.btnLabel')}
            </Button>
          </FormControl>
        </>
      )}
    </Box>
  );
};

export default Step1;
