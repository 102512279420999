import { createContext } from 'react';

export interface IMicroFrontendContextProps {
  getJWT: () => Promise<string | undefined | null>;
  groups: string[];
  firstName?: string;
  lastName?: string;
}

const MicroFrontendContext = createContext<IMicroFrontendContextProps>({
  getJWT: async () => {
    return null;
  },
  groups: [],
});

export default MicroFrontendContext;
