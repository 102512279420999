import {
  ICreateSurveyMappingsRequestBody,
  ICreateSurveyMappingsResponse,
  IEventResponse,
  IProductResponse,
  IQuestionMappingResponse,
  ISurveyDataApiResponse,
  ISurveyLinkResponse,
  ISurveyMappingsResponse,
  ISurveyQuestionResponse,
  ISurveyResponse,
  ITechnicalQuestionIdResponse,
} from '@shared/interfaces';
import mergedConfig from '../config';
import { ISurveyLinkErrorResponse } from '@shared/interfaces/src/survey-link';

export class CockpitApiError extends Error {
  errorCode?: string;

  constructor(message: string, errorCode?: string) {
    super(message);
    this.errorCode = errorCode;
  }
}

export const getApiService = (getJWT: () => Promise<string | undefined | null>) => {
  const apiRequest = async (
    path: string,
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    body?: Record<string, any>,
    params?: Record<string, string>
  ) => {
    const jwt = await getJWT();
    if (!jwt) {
      throw new Error('Could not get JWT');
    }

    const result = await fetch(`${mergedConfig.API_URL}/${path}?${new URLSearchParams(params)}`, {
      method,
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        authorization: jwt ?? '',
      },
    });

    if (result.status >= 400) {
      const data = await result.json();

      throw new CockpitApiError('Cockpit API request failed', data?.errorCode);
    }

    return await result.text();
  };

  const addSurveyMapping = async (data: ICreateSurveyMappingsRequestBody[]): Promise<ICreateSurveyMappingsResponse> => {
    const response = await apiRequest('survey-mappings', 'PUT', data);

    return JSON.parse(response) as ICreateSurveyMappingsResponse;
  };

  const deleteSurveyMapping = async (idType: string, id: string, surveyType: string): Promise<void> => {
    await apiRequest(`survey-mappings/${idType}/${id}/${surveyType}`, 'DELETE');
  };

  const getEventDataByBnr = async (bookingNumber: string, endDate?: string): Promise<IEventResponse[]> => {
    const data = await apiRequest('event-data', 'GET', undefined, { bookingNumber, ...(endDate && { endDate }) });

    return JSON.parse(data) as IEventResponse[];
  };

  const getEventDataByBusinessUnit = async (businessUnit: string, endDate?: string): Promise<IEventResponse[]> => {
    const response = await apiRequest('event-data', 'GET', undefined, {
      businessUnit,
      ...(endDate && { endDate }),
    });

    return JSON.parse(response) as IEventResponse[];
  };

  const getProductsData = async (ids: string[]): Promise<IProductResponse[]> => {
    const data = await apiRequest('productData', 'GET', undefined, { listOfBnr: `["${ids.join('","')}"]` });

    return JSON.parse(data) as IProductResponse[];
  };

  const getQuestionMappings = async (surveyId: string): Promise<Record<string, IQuestionMappingResponse>> => {
    const data = await apiRequest(`surveys/${surveyId}/question-mappings`, 'GET');
    const result = JSON.parse(data) as IQuestionMappingResponse[];
    return result.reduce((acc, curr) => {
      const { questionId, ...rest } = curr;
      return {
        ...acc,
        [questionId]: rest,
      };
    }, {});
  };

  const getSurveyLinks = async (eventId: string): Promise<(ISurveyLinkResponse | ISurveyLinkErrorResponse)[]> => {
    const data = await apiRequest('survey-links', 'GET', undefined, { eventIds: eventId });

    return JSON.parse(data) as (ISurveyLinkResponse | ISurveyLinkErrorResponse)[];
  };

  const getSurveyMappings = async (
    idType?: string,
    id?: string,
    mappingType?: string
  ): Promise<ISurveyMappingsResponse> => {
    const path = ['survey-mappings', idType, id].filter((item) => typeof item !== 'undefined').join('/');

    const data = await apiRequest(path, 'GET', undefined, {
      ...(mappingType && { mappingType }),
    });

    return JSON.parse(data) as ISurveyMappingsResponse;
  };

  const getSurveyQuestions = async (surveyId: string): Promise<ISurveyQuestionResponse[]> => {
    const data = await apiRequest(`survey-questions/${surveyId}`, 'GET');

    return JSON.parse(data) as ISurveyQuestionResponse[];
  };

  const getSurveysData = async (): Promise<ISurveyResponse[]> => {
    const data = await apiRequest('surveys', 'GET');

    return JSON.parse(data) as ISurveyResponse[];
  };

  const getTechnicalQuestionIds = async (): Promise<ITechnicalQuestionIdResponse[]> => {
    const data = await apiRequest('technical-question-ids', 'GET');

    return JSON.parse(data) as ITechnicalQuestionIdResponse[];
  };

  const updateSurveyLink = async (id: string, validityExtensionInDays: number): Promise<ISurveyLinkResponse> => {
    const data = await apiRequest(`survey-links/${id}`, 'PATCH', {
      validityExtensionInDays: validityExtensionInDays,
    });

    return JSON.parse(data) as ISurveyLinkResponse;
  };

  const saveQuestionMapping = async (
    surveyId: string,
    questionId: string,
    technicalQuestionId?: string,
    scope?: string
  ): Promise<IQuestionMappingResponse> => {
    const data = await apiRequest(`surveys/${surveyId}/question-mappings/${questionId}`, 'PUT', {
      technicalQuestionId,
      scope: !scope || scope === 'undefined' ? undefined : scope,
    });

    return JSON.parse(data) as IQuestionMappingResponse;
  };

  const saveTechnicalQuestionId = async (
    id: string,
    labels: { de: string; en?: string }
  ): Promise<ITechnicalQuestionIdResponse> => {
    const data = await apiRequest(`technical-question-ids/${id}`, 'PUT', { labels });

    return JSON.parse(data) as ITechnicalQuestionIdResponse;
  };

  const getReportData = async (eventId: string | undefined): Promise<ISurveyDataApiResponse> => {
    if (typeof eventId === 'undefined') {
      return Promise.reject(new Error('Invalid event ID'));
    }
    const result = await apiRequest(`events/${eventId}/feedback-report`, 'GET');
    return JSON.parse(result) as unknown as ISurveyDataApiResponse;
  };

  return {
    addSurveyMapping,
    deleteSurveyMapping,
    getEventDataByBnr,
    getEventDataByBusinessUnit,
    getProductsData,
    getQuestionMappings,
    getReportData,
    getSurveyLinks,
    getSurveyMappings,
    getSurveyQuestions,
    getSurveysData,
    getTechnicalQuestionIds,
    updateSurveyLink,
    saveQuestionMapping,
    saveTechnicalQuestionId,
  };
};
