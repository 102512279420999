import { Box } from '@mui/material';
import React, { FunctionComponent } from 'react';

interface ITabPanelProps {
  children?: React.ReactNode;
  actualTab: string;
  value: string;
}

const TabPanel: FunctionComponent<ITabPanelProps> = ({ children, actualTab, value, ...other }) => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== actualTab}
      id={`scrollable-auto-tabpanel-${actualTab}`}
      aria-labelledby={value}
      {...other}
    >
      {value === actualTab && <Box p={0}>{children}</Box>}
    </Box>
  );
};

export default TabPanel;
