import { FunctionComponent } from 'react';
import ChartBox from '../ChartBox';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { palette } from '../Report';

interface IChartChoiceProps {
  title: string;
  data: string[];
}

interface IChoice {
  label: string;
  value: number;
}

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

export const ChartChoice: FunctionComponent<IChartChoiceProps> = ({ title, data }) => {
  const getColorFormatted = () => {
    const data = getFormattedData();
    const dataLowcase = data.map((element) => element.label.toLowerCase());
    // if it's a YES/NO question, the palette is hardcoded define
    if (dataLowcase.includes('ja') && dataLowcase.includes('nein') && dataLowcase.length === 2) {
      return dataLowcase.map((value) => (value === 'ja' ? palette[0] : palette[3]));
    } else {
      return palette;
    }
  };

  const getFormattedData = () => {
    const result = data.reduce(
      (acc, item) => {
        if (acc[item]) {
          acc[item].value += 1;
        } else {
          acc[item] = { label: item, value: 1 };
        }
        return acc;
      },
      {} as { [key: string]: IChoice }
    );

    return Object.values(result);
  };

  return (
    <ChartBox title={title}>
      <div style={{ height: '600px' }}>
        <Doughnut
          options={{
            animation: false,
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
              legend: {
                position: 'right',
              },
              datalabels: {
                color: '#444',
                clamp: true,
                labels: {
                  title: {
                    font: {
                      weight: 'bold',
                    },
                  },
                },
                formatter: function (value, context) {
                  return `${getFormattedData()[context.dataIndex].label}: ${value}`;
                },
              },
            },
          }}
          data={{
            labels: getFormattedData().map((a) => a.label),
            datasets: [
              {
                data: getFormattedData().map((a) => a.value),
                backgroundColor: getColorFormatted(),
              },
            ],
          }}
        />
      </div>
    </ChartBox>
  );
};
