import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import MicroFrontendContext from '../../components/MicroFrontendContext';
import { getApiService } from '../../api/api-request';

interface ITechnicalQuestionId {
  id: string;
  label?: string;
  labels?: {
    de: string;
    en?: string;
  };
}

const TechnicalQuestionIdsPage: FunctionComponent = () => {
  const { t } = useTranslation();

  const [technicalQuestionIdsLoading, setTechnicalQuestionIdsLoading] = useState(true);
  const [technicalQuestionIds, setTechnicalQuestionIds] = useState<ITechnicalQuestionId[]>([]);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedTechnicalQuestionId, setSelectedTechnicalQuestionId] = useState<ITechnicalQuestionId>();
  const [newIdValue, setNewIdValue] = useState<string>();
  const [newLabelDEValue, setNewLabelDEValue] = useState<string>();
  const [newLabelENValue, setNewLabelENValue] = useState<string>();
  const [saveError, setSaveError] = useState('');
  const [saving, setSaving] = useState(false);

  const { getJWT } = useContext(MicroFrontendContext);

  const initialize = async () => {
    setTechnicalQuestionIdsLoading(true);
    const apiService = getApiService(getJWT);
    try {
      const technicalQuestionIdsResponse = await apiService.getTechnicalQuestionIds();
      setTechnicalQuestionIds(
        technicalQuestionIdsResponse.sort((a: ITechnicalQuestionId, b: ITechnicalQuestionId) =>
          a.id.localeCompare(b.id)
        )
      );
    } catch (error) {
      // TODO: show error message
    } finally {
      setTechnicalQuestionIdsLoading(false);
    }
  };

  const handleNewItemClick = () => {
    setEditDialogOpen(true);
  };

  const handleEditClick = (technicalQuestionId: ITechnicalQuestionId) => {
    setEditDialogOpen(true);
    setSelectedTechnicalQuestionId(technicalQuestionId);
    setNewLabelDEValue(technicalQuestionId.labels?.de ?? technicalQuestionId.label);
    setNewLabelENValue(technicalQuestionId.labels?.en);
    // setNewLabelValue(technicalQuestionId.label);
  };

  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
    setSelectedTechnicalQuestionId(undefined);
    setSaveError('');
    setSelectedTechnicalQuestionId(undefined);
    setNewLabelDEValue('');
    setNewLabelENValue('');
    setNewIdValue('');
  };

  const addOrUpdateTechnicalQuestionId = (technicalQuestionId: ITechnicalQuestionId) => {
    setTechnicalQuestionIds((current) => {
      return current.some((item) => item.id === technicalQuestionId.id)
        ? current.map((item) =>
            item.id === technicalQuestionId.id
              ? {
                  ...technicalQuestionId,
                  id: item.id,
                }
              : item
          )
        : [...current, technicalQuestionId];
    });
  };

  const handleSave = async () => {
    setSaving(true);
    const apiService = getApiService(getJWT);
    try {
      if (newLabelDEValue) {
        await apiService.saveTechnicalQuestionId(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          selectedTechnicalQuestionId?.id ?? newIdValue!,
          {
            de: newLabelDEValue,
            en: newLabelENValue,
          }
        );
        setEditDialogOpen(false);
        addOrUpdateTechnicalQuestionId({
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          id: selectedTechnicalQuestionId?.id ?? newIdValue!,
          labels: {
            de: newLabelDEValue,
            en: newLabelENValue,
          },
        });
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setSaveError(t('pages.technicalQuestionIds.saveError', { errorMessage: error.message }));
    } finally {
      setSaving(false);
      setSelectedTechnicalQuestionId(undefined);
      setNewLabelDEValue('');
      setNewLabelENValue('');
      setNewIdValue('');
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  return (
    <>
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>{t('pages.technicalQuestionIds.tableHeaders.id')}</TableCell>
              <TableCell>{t('pages.technicalQuestionIds.tableHeaders.labelDE')}</TableCell>
              <TableCell>{t('pages.technicalQuestionIds.tableHeaders.labelEN')}</TableCell>
              <TableCell>{t('pages.technicalQuestionIds.tableHeaders.actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {technicalQuestionIdsLoading && (
              <TableRow>
                <TableCell colSpan={4}>
                  <LinearProgress />
                </TableCell>
              </TableRow>
            )}
            {technicalQuestionIds.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.id}</TableCell>
                <TableCell>{row.labels?.de ?? row.label}</TableCell>
                <TableCell>{row.labels?.en}</TableCell>
                <TableCell>
                  <IconButton color="inherit" size="small" aria-label="edit" onClick={() => handleEditClick(row)}>
                    <EditIcon fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog fullWidth open={editDialogOpen} onClose={handleEditDialogClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{t('pages.technicalQuestionIds.edit.header')}</DialogTitle>
        <DialogContent>
          {selectedTechnicalQuestionId ? (
            <DialogContentText paddingBottom={2}>{selectedTechnicalQuestionId?.id}</DialogContentText>
          ) : (
            <TextField
              autoFocus
              margin="dense"
              id="id"
              label={t('pages.technicalQuestionIds.edit.id')}
              type="text"
              value={newIdValue}
              onChange={(event) => setNewIdValue(event.target.value)}
              fullWidth
            />
          )}
          {saveError && <Alert severity="error">{saveError}</Alert>}
          <TextField
            margin="dense"
            id="name"
            label={t('pages.technicalQuestionIds.edit.labelDE')}
            type="text"
            value={newLabelDEValue}
            onChange={(event) => setNewLabelDEValue(event.target.value)}
            fullWidth
          />
          <TextField
            margin="dense"
            id="name"
            label={t('pages.technicalQuestionIds.edit.labelEN')}
            type="text"
            value={newLabelENValue}
            onChange={(event) => setNewLabelENValue(event.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={saving} onClick={handleEditDialogClose} color="primary">
            {t('pages.technicalQuestionIds.edit.cancel')}
          </Button>
          <Button disabled={saving || !newLabelDEValue} onClick={handleSave} color="primary">
            {t('pages.technicalQuestionIds.edit.save')}
          </Button>
        </DialogActions>
        {saving && <LinearProgress />}
      </Dialog>
      <Fab
        style={{
          margin: 0,
          top: 'auto',
          right: 20,
          bottom: 20,
          left: 'auto',
          position: 'fixed',
        }}
        color="primary"
        aria-label="add"
        onClick={handleNewItemClick}
      >
        <AddIcon />
      </Fab>
    </>
  );
};

export default TechnicalQuestionIdsPage;
