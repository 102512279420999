import { Grid, Link, Typography } from '@mui/material';
import { format } from 'date-fns';
import { FunctionComponent } from 'react';
import { ISurveyDataEventApiResponse } from '@shared/interfaces/src/survey-data';
import { useTranslation } from 'react-i18next';

interface IEventInfoProps {
  eventData: ISurveyDataEventApiResponse;
  answersCount: {
    participant: number;
    trainer: number;
  };
  NPS?: number;
}

export const EventInfo: FunctionComponent<IEventInfoProps> = ({ eventData, answersCount, NPS }) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2} mb={8} mt={3}>
      <Grid item xs={9}>
        <Typography variant="h4" fontSize={32} mb={1} sx={{ fontWeight: 300 }}>
          {eventData.title}
        </Typography>
        <Typography variant="h6" fontWeight={300} fontSize={18}>
          {eventData.eventId}
        </Typography>
        <Typography variant="h6" fontWeight={300} fontSize={18}>
          {[
            ...new Set([
              format(new Date(eventData.startDate), t('formatDate')),
              format(new Date(eventData.endDate), t('formatDate')),
            ]),
          ].join(' - ')}
        </Typography>
        <Typography variant="h6" fontWeight={300} fontSize={18}>{`${t('pages.feedbackReport.report.feedbackCount')}: ${
          answersCount.participant
        }`}</Typography>
        <Typography variant="h6" fontWeight={300} fontSize={18}>{`${t(
          'pages.feedbackReport.report.feedbackCountTainer'
        )}: ${answersCount.trainer}`}</Typography>
        {typeof NPS === 'number' && (
          <Typography variant="h6" fontWeight={300} fontSize={18}>
            {`${t('pages.feedbackReport.report.NPS')}: `}
            <b>{NPS.toFixed(2)}</b>
          </Typography>
        )}
        <Typography variant="h6" fontWeight={300} fontSize={18}>
          <Link href={eventData.salesforceLink} target="_blank">
            {t('pages.feedbackReport.report.salesforceLink')}
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
};
