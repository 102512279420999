import { Box, Paper, Typography } from '@mui/material';
import { FunctionComponent, ReactNode } from 'react';

interface IChartBoxProps {
  children: ReactNode;
  title?: string;
}

const ChartBox: FunctionComponent<IChartBoxProps> = ({ children, title }) => {
  return (
    <Box component={Paper} p={2} my={2} sx={{ breakInside: 'avoid' }}>
      {title && (
        <Typography variant="h6" marginBottom={2}>
          {title}
        </Typography>
      )}
      {children}
    </Box>
  );
};

export default ChartBox;
