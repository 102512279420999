export const i18n_de = {
  modal: {
    details: 'Details',
  },
  common: {
    cancel: 'Abbrechen',
    delete: 'Löschen',
    reallyDelete: 'Eintrag wirklich löschen?',
  },
  pages: {
    feedbackReport: {
      errors: {
        EVENT_NOT_FOUND: 'Veranstaltung wurde nicht gefunden',
        UNKNOWN: 'Beim Laden der Auswertung ist ein Fehler aufgetreten',
        UNSUPPORTED_BUSINESS_UNIT: 'Auswertungen können nur fuer die Geschäftsbereiche IHC und ILS angezeigt werden',
        undefined:
          'Fehler beim API Aufruf. Bitte lass deine Berechtigungen überprüfen oder kontaktiere _AKA_Domaene_LOS@haufe-lexware.com',
      },
      form: {
        eventIdLabel: 'VA-ID',
        submitButtonLabel: 'Report Anzeigen',
      },
      report: {
        feedbackCount: 'Anzahl Antworten',
        feedbackCountTainer: 'Anzahl Antworten Trainer',
        noData: 'Zu dieser Veranstaltung ist kein Feedback vorhanden.',
        NPS: 'NPS',
        salesforceLink: 'Salesforce Link',
      },
      reportData: {
        trainerFeedback: 'Trainer-Feedback',
        trainerReport: 'Trainer Auswertung',
        participantFeedback: 'Teilnehmer-Feedback',
      },
    },
    mappingQuestions: {
      saveMappingErrorMessage: 'Fehler beim Speichern des Mappings',
      saveMappingSuccessMessage: 'Mapping gespeichert!',
      surveyListLabel: 'Fragebogen',
      showDeletedQuestions: 'Gelöschte Fragen anzeigen',
      selectSurveyHint: 'Fragebogen aus Liste auswählen',
      loadingSurveys: 'Fragebogen werden geladen...',
      tableHeaders: {
        question: 'Frage',
        technicalQuestionId: 'Technische Fragen-ID',
        scope: 'Scope',
        actions: 'Aktionen',
      },
      scopes: {
        noScope: '-- LEER --',
        arbeitsrecht: 'Arbeitsrecht',
        lohnsteuer: 'Lohnsteuer',
        sozialversicherung: 'Sozialversicherung',
        personalstrategie: 'Personalstrategie',
        trainer1: 'Trainer 1',
        trainer2: 'Trainer 2',
        trainer3: 'Trainer 3',
        trainer4: 'Trainer 4',
        trainer5: 'Trainer 5',
      },
    },
    technicalQuestionIds: {
      tableHeaders: {
        id: 'ID',
        labelDE: 'Label (DE)',
        labelEN: 'Label (EN)',
        actions: 'Aktionen',
      },
      edit: {
        save: 'Speichern',
        cancel: 'Abbrechen',
        labelDE: 'Label (DE)',
        labelEN: 'Label (EN)',
        header: 'Bearbeiten',
        id: 'Technische ID',
      },
      saveError: 'Fehler beim Speichern: {{errorMessage}}',
    },
    fbLinks: {
      title: 'Feedback Links',
      search: 'Suchen',
      inputHint: 'Eine oder mehrere VAs durch Leerzeichen oder "," getrennt. Z.B.: "123 456" oder "123,456"',
      notFound: 'Keine Links gefunden',
      error: 'Fehler beim Laden der Links',
      errorCode_NO_MAPPING: 'Es gibt kein entsprechendes Mapping',
      errorCode_EVENT_DISABLED: 'Feedbacklink wurde manuell deaktiviert',
      errorCode_UNKNOWN_ERROR: 'Fehler beim Laden der Links',
      errorCode_NOT_AVAILABLE: 'Feedbacklink für diese Veranstaltung ist nicht verfügbar',
      alerts: {
        linkCopied: 'Link kopiert',
        linkUpdated: 'Neue Gültigkeit gespeichert',
        linkUpdateFailed: 'Fehler beim speichern',
      },
      editDialog: {
        cancel: 'Abbrechen',
        save: 'Speichern',
        title: 'Link bearbeiten',
        validityExtensionInDays: {
          label: 'Gültigkeit erweitern',
          startAdornment: 'Gültigkeit um',
          endAdornment: 'Tage erweitern',
        },
        newValidToDateLabel: 'Neue Gültigkeit bis:',
      },
      table: {
        headers: {
          actions: 'Aktionen',
          eventId: 'VA ID',
          surveyType: 'Feedbackbogentyp',
          trainerId: 'Trainer ID',
          url: 'Link',
          validFrom: 'Gültig ab',
          validUntil: 'Gültig bis',
          surveyDistributionType: 'Auslieferungsart',
        },
        actions: {
          copyLink: 'Link kopieren',
          downloadQrCode: 'QR-Code downloaden',
          csvDownload: 'CSV-Export',
          extendValidity: 'Gültigkeit anpassen',
        },
        extensionInfoTooltip: '+{{days}} Tage',
      },
    },
    mappingSurvey: {
      title: 'Mappings Feedbackbögen',
      createMapping: 'Mapping erstellen',
      mappingsNotFound: 'Keine Mappings gefunden',
      surveyTypes: {
        PARTICIPANT_FEEDBACK: 'Teilnehmer-Feedback',
        TRAINER_FEEDBACK: 'Trainer-Feedback',
        BETREUER_FEEDBACK: 'Betreuer-Feedback',
        TECHNIK_SUPPORT_FEEDBACK: 'Technik & Support Feedback',
      },
      idTypes: {
        BOOKING_NUMBER: 'BNR',
        FORMAT: 'Format',
        BUSINESS_UNIT: 'Geschäftsbereich',
      },
      surveyDistributionTypes: {
        LU: 'Lernumgebung',
        EMAIL: 'Email',
        AU: 'Arbeitsumgebung',
        PRINT: 'Print',
        NONE: 'Keine Auslieferung',
        MANUAL: 'Manuell',
        BU: 'Betreuerumgebung',
        OSA_APP: 'OSA-App',
      },
      table: {
        headers: {
          bookingNumber: 'Mapping',
          productTitle: 'Titel',
          surveyType: 'Feedbackbogentyp',
          surveyTitle: 'Feedbackbogentitel',
          surveyDistributionType: 'Auslieferungsart',
          active: 'Aktiv',
          startDate: 'Beginn',
          endDate: 'Ende',
          actions: 'Aktionen',
        },
      },
      steps: {
        step1: {
          title: 'Buchungsnummern',
          inputLabel: 'Buchungsnummer',
          inputHint:
            'Eine oder mehrere Buchungsnummern durch Leerzeichen oder "," getrennt. Z.B.: "123 456" oder "123,456"',
          inputLabelFormat: 'Format',
          inputHintFormat: 'Ein oder mehrere Formate hinterlegen Bsp. 551',
          btnLabel: 'Weiter',
        },
        step2: {
          title: 'Feedbackbogen auswählen',
          selectedProducts: 'Produkte',
          mappingsExistedTitle: 'Die folgenden Produkte haben bereits ein Mapping:',
          mappingsExistedHint: 'Die bestehenden Mappings werden überschrieben',
          product: 'Produkt',
          survey: 'Feedbackbogen',
          selectInputLabel: 'Bitte Feedbackbogen auswählen',
          surveyTypeSelectInputLabel: 'Bitte Feedbackbogentyp wählen',
          surveyDistributionTypeSelectInputLabel: 'Bitte Auslieferung wählen',
          surveyDistributionTypeDisabled: '(noch nicht verfügbar)',
          btnLabel: 'Weiter',
          productNotFound: 'Zur Buchungsnummer wurde kein Produkt gefunden',
          btnBackLabel: 'Zurück',
        },
        step3: {
          title: 'Mappings erstellen',
          selectedProducts: 'Produkte',
          selectedFormats: 'Formate',
          selectedSurvey: 'Feedbackbogen',
          selectedSurveyType: 'Feedbackbogentyp',
          selectedSurveyDistributionsType: 'Auslieferungsart',
          btnLabel: 'Mappings erstellen',
          btnBackLabel: 'Zurück',
        },
        finished: {
          alertFailed: '{{failedLength}} Mapping(s) konnten nicht erstellt werden.',
          alertSuccess: '{{successLength}} Mapping(s) wurden erstellt',
        },
      },
    },
    surveyMappingInhouse: {
      title: 'Mappings Feedbackbögen {{businessUnit}}',
    },
    // Overwrite of mappingSurvey
    mappingSurveyILS: {
      title: 'Mappings Feedbackbögen ILS',
      table: {
        headers: {
          bookingNumber: 'BNR',
        },
      },
      filterByEventId: 'Suche nach Event ID',
      mappingUpdateSuccess: 'Update Mapping erfolgreich',
      mappingUpdateFail: 'Update Mapping fehlgeschlagen',
      mappingDeleteSuccess: 'Mapping löschen erfolgreich',
      mappingDeleteFail: 'Mapping löschen fehlgeschlagen',
      emptyFilteredEvents: 'Event ID nicht gefunden',
      emptyEvents: 'Keine zukünftigen Veranstaltungen verfügbar',
    },
    mappingSurveyIHC: {
      title: 'Mappings Feedbackbögen IHC',
      mappingUpdateSuccess: 'Update Mapping erfolgreich',
      mappingUpdateFail: 'Update Mapping fehlgeschlagen',
    },
  },
  formatDate: 'dd.MM.yyyy',
  formatDateWithTime: 'dd.MM.yyyy HH:mm',
};
