import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { Alert, Box, LinearProgress } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import { useFeedbackReport } from '../../queries/feedback-report';
import { FeedbackReportForm } from '../../components/FeedbackReport/Form';
import MicroFrontendContext from '../../components/MicroFrontendContext';
import { FeedbackReport } from '../../components/FeedbackReport/Report';
import { EventInfo } from '../../components/FeedbackReport/EventInfo';
import { useTranslation } from 'react-i18next';
import { CockpitApiError } from '../../api/api-request';

export const FeedbackReportPage: FunctionComponent<unknown> = () => {
  const { eventId: pathParamEventId } = useParams();
  const { hash } = useLocation();

  const [eventId, setEventId] = useState(pathParamEventId ?? '');

  const tabId = hash.slice(1) !== '' ? hash.slice(1) : 'participant-feedback';
  const { getJWT } = useContext(MicroFrontendContext);
  const { data: feedbackReport, isLoading: feedbackReportLoading, error } = useFeedbackReport(eventId, getJWT);

  const { t } = useTranslation();

  useEffect(() => {
    if (pathParamEventId) {
      handleSubmit(pathParamEventId);
    }
  }, [pathParamEventId]);

  const handleSubmit = (eventIdParam: string) => {
    setEventId(eventIdParam);
  };

  const errorCode = error && error instanceof CockpitApiError ? error.errorCode : 'UNKNOWN';

  return (
    <Box sx={{ padding: 3, flex: 1 }}>
      <FeedbackReportForm handleSubmit={handleSubmit} />
      {feedbackReportLoading && <LinearProgress />}

      {error && (
        <Alert sx={{ mt: 5 }} severity="error">
          {t(`pages.feedbackReport.errors.${errorCode}`)}
        </Alert>
      )}

      {!feedbackReportLoading && feedbackReport && (
        <>
          {feedbackReport.eventData && (
            <EventInfo
              answersCount={{
                participant: feedbackReport.surveyDataResult.partipantFeedback.length,
                trainer: feedbackReport.surveyDataResult.trainerFeedback.length,
              }}
              eventData={feedbackReport.eventData}
              NPS={feedbackReport.surveyDataResult.NPS}
            />
          )}
          <FeedbackReport surveyDataResult={feedbackReport.surveyDataResult} tabId={tabId} />
        </>
      )}
    </Box>
  );
};
