import { FunctionComponent } from 'react';
import ChartBox from '../ChartBox';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import { palette } from '../Report';
import { useTranslation } from 'react-i18next';
import { IGroup } from '../../../utils/parseSurveyDataResponse';

interface IChartGroupProps {
  title: string;
  data: IGroup;
}

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

export const ChartGroup: FunctionComponent<IChartGroupProps> = ({ title, data }) => {
  const { i18n } = useTranslation();

  const getPaletteColor = (key: string) => {
    switch (key) {
      case 'trifft voll zu':
        return palette[0];
      case 'trifft eher zu':
        return palette[1];
      case 'trifft eher nicht zu':
        return palette[2];
      case 'trifft nicht zu':
        return palette[3];
      case 'keine Angabe':
        return palette[4];
      default:
        return palette[0];
    }
  };

  const getAnswerDataSet = () => {
    const choices = data.choices[0] === 'keine Angabe' ? [...data.choices].reverse() : data.choices;
    return choices.map((choice: string) => {
      return {
        label: i18n.t(choice),
        data: Object.keys(data.questions).map(
          (k) => data.questions[k].answers.filter((x: string) => x === choice).length
        ),
        backgroundColor: getPaletteColor(choice),
      };
    });
  };

  return (
    <ChartBox title={i18n.t(title)}>
      <Bar
        options={{
          animation: false,
          color: '#444',
          plugins: {
            title: {
              display: false,
            },
            legend: {
              labels: {
                color: '#444',
                font: {
                  size: 14,
                },
              },
            },
            datalabels: {
              color: '#444',
              labels: {
                title: {
                  font: {
                    weight: 'bold',
                  },
                },
              },
              formatter: function (value) {
                return value !== 0 ? value : null;
              },
            },
          },
          indexAxis: 'y',
          responsive: true,
          aspectRatio: 1 | 4,
          scales: {
            x: {
              stacked: true,
              ticks: {
                color: '#444',
                precision: 0,
              },
            },
            y: {
              stacked: true,
              ticks: {
                color: '#444',
                font: {
                  size: 14,
                },
              },
            },
          },
        }}
        data={{
          labels: Object.keys(data.questions).map((k) => {
            const labels = data.questions[k].labels;
            const label = labels ? labels.de : data.questions[k].text;
            return label;
          }),
          datasets: getAnswerDataSet(),
        }}
      />
    </ChartBox>
  );
};
