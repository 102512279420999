import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  InputAdornment,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { addDays } from 'date-fns';
import { format } from 'date-fns/esm';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getApiService } from '../../api/api-request';
import { ISurveyLinkResponse } from '@shared/interfaces';
import MicroFrontendContext from '../../components/MicroFrontendContext';

interface IEditSurveyLinkDialogProps {
  surveyLink: ISurveyLinkResponse | null;
  showDialog: boolean;
  setShowDialog: (open: boolean) => void;
  onSaveCallback: (surveyLink: ISurveyLinkResponse) => void;
  onCloseCallback: () => void;
}

const useStyles = makeStyles((_theme: Theme) => ({
  progress: {
    textAlign: 'center',
  },
  button: {
    minWidth: 0,
  },
  alert: {
    marginTop: '20px',
  },
}));

const EditSurveyLinkDialog: FunctionComponent<IEditSurveyLinkDialogProps> = ({
  surveyLink,
  showDialog,
  setShowDialog,
  onSaveCallback,
  onCloseCallback,
}) => {
  const { t } = useTranslation();

  const { getJWT } = useContext(MicroFrontendContext);

  const handleClose = () => {
    setShowDialog(false);
    resetState();
    onCloseCallback();
  };

  const [validityExtensionInDays, setValidityExtensionInDays] = useState(surveyLink?.validityExtensionInDays ?? 0);
  const [processing, setProcessing] = useState(false);
  const [updatedValidTo, setUpdatedValidTo] = useState<Date | undefined>(
    surveyLink?.validTo ? new Date(surveyLink.validTo) : undefined
  );

  useEffect(() => {
    if (surveyLink?.validTo && !isNaN(validityExtensionInDays)) {
      setUpdatedValidTo(
        addDays(new Date(surveyLink.validTo), validityExtensionInDays - (surveyLink.validityExtensionInDays ?? 0))
      );
    }
  }, [validityExtensionInDays, surveyLink]);

  useEffect(() => {
    if (surveyLink?.validityExtensionInDays) {
      setValidityExtensionInDays(surveyLink.validityExtensionInDays);
    }
  }, [surveyLink]);

  const classes = useStyles();

  const resetState = () => {
    setProcessing(false);
    setValidityExtensionInDays(0);
  };

  const handleSave = async () => {
    setProcessing(true);
    try {
      const linkId = surveyLink?.url.split('/').pop();
      if (linkId) {
        const apiService = getApiService(getJWT);
        const updatedSurveyLink = await apiService.updateSurveyLink(linkId, validityExtensionInDays);
        onSaveCallback(updatedSurveyLink);
      }
    } catch (error) {
    } finally {
      handleClose();
    }
  };

  return (
    <Dialog
      open={showDialog}
      onClose={handleClose}
      aria-labelledby="edit-survey-link-dialog-title"
      aria-describedby="edit-survey-link-dialog-description"
    >
      <DialogTitle id="edit-survey-link-dialog">
        {t('pages.fbLinks.editDialog.validityExtensionInDays.label')}
      </DialogTitle>
      <DialogContent>
        {processing ? (
          <div className={classes.progress}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <Input
              id="standard-number"
              type="number"
              value={validityExtensionInDays}
              inputProps={{ min: '0', max: '100' }}
              startAdornment={
                <InputAdornment position="start">
                  {t('pages.fbLinks.editDialog.validityExtensionInDays.startAdornment')}
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  {t('pages.fbLinks.editDialog.validityExtensionInDays.endAdornment')}
                </InputAdornment>
              }
              style={{ maxWidth: '280px' }}
              onChange={(e) => setValidityExtensionInDays(parseInt(e.target.value))}
            />
            <Alert className={classes.alert} severity="info" style={{ margin: '1.5rem 0 1rem 0' }}>
              {t('pages.fbLinks.editDialog.newValidToDateLabel')}{' '}
              {updatedValidTo && format(updatedValidTo, t('formatDateWithTime'))}
            </Alert>
          </>
        )}
      </DialogContent>
      {!processing && (
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('pages.fbLinks.editDialog.cancel')}
          </Button>
          <Button onClick={handleSave} color="primary" autoFocus>
            {t('pages.fbLinks.editDialog.save')}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default EditSurveyLinkDialog;
