import { FunctionComponent, useState } from 'react';
import { ISurveyDataFeedbackResponse } from '@shared/interfaces/src/survey-data';
import { ChartText } from './charts/ChartText';
import { ChartChoice } from './charts/ChartChoice';

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { ChartGroup } from './charts/ChartGroup';
import { ChartNPS } from './charts/ChartNPS';
import { useTranslation } from 'react-i18next';
import { Alert, AppBar, Box, Tab, Tabs } from '@mui/material';
import { IGroup, IProcessedData, IQuestion, parseSurveyDataResponse } from '../../utils/parseSurveyDataResponse';
import TabPanel from './TabPanel';

interface IChartsProps {
  surveyDataResult: ISurveyDataFeedbackResponse;
  tabId: string;
}

interface ISurveyDataTrainer {
  trainerId: string;
  data: IProcessedData;
}

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const palette = ['#00ABF7', '#b5e1fa', '#ffd499', '#ff9300', '#e6e6e6'];

export const FeedbackReport: FunctionComponent<IChartsProps> = ({ surveyDataResult, tabId }) => {
  const { t } = useTranslation();
  const [tab, setTab] = useState(tabId);

  // Get all trainerIds
  const trainerData = surveyDataResult.trainerFeedback.reduce<ISurveyDataTrainer[]>((accumulator, current) => {
    const currentId = current.surveyType.split('#')[1];
    if (currentId && !accumulator.find((item) => item.trainerId === currentId)) {
      //get all feedbackbacks for one trainerid
      const trainerResponses = surveyDataResult.trainerFeedback.filter((trainerResponse) => {
        return trainerResponse.surveyType === current.surveyType;
      });
      accumulator.push({
        trainerId: currentId,
        data: parseSurveyDataResponse(trainerResponses),
      });
    }
    return accumulator;
  }, []);

  const participantData = parseSurveyDataResponse(surveyDataResult.partipantFeedback);
  const showParticipants = Object.keys(participantData).length > 0;

  const getTranslatedLabel = (data: IQuestion | IGroup) => {
    if (data.labels) {
      return data.labels.de;
    } else {
      return data.text;
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (_event: any, newValue: string) => {
    setTab(newValue);
  };

  const parseParticipantFeedback = (data: IProcessedData) => {
    return Object.keys(data).map((key) => {
      if (data[key].type === 'Text') {
        return <ChartText key={key} title={getTranslatedLabel(data[key])} data={(data[key] as IQuestion).answers} />;
      }
      if (data[key].type === 'Choice') {
        return <ChartChoice key={key} title={data[key].text} data={(data[key] as IQuestion).answers} />;
      }
      if (data[key].type === 'MatrixChoice') {
        return <ChartGroup key={key} title={data[key].text} data={data[key] as IGroup} />;
      }
      if (data[key].type === 'NPS') {
        return <ChartNPS key={key} title={getTranslatedLabel(data[key])} data={(data[key] as IQuestion).answers} />;
      }
    });
  };

  const parseTrainerFeedbackPanel = (surveyData: ISurveyDataTrainer) => {
    return (
      <TabPanel key={surveyData.trainerId} actualTab={tab} value={`trainer-feedback-${surveyData.trainerId}`}>
        {Object.keys(surveyData.data).map((key) => {
          return (
            <ChartText key={key} title={surveyData.data[key].text} data={(surveyData.data[key] as IQuestion).answers} />
          );
        })}
      </TabPanel>
    );
  };

  if (surveyDataResult.partipantFeedback.length === 0 && surveyDataResult.trainerFeedback.length === 0) {
    return (
      <Box sx={{ mt: 5 }}>
        <Alert severity="info">{t('pages.feedbackReport.report.noData')}</Alert>
      </Box>
    );
  }

  return (
    <>
      {(showParticipants || trainerData.length > 0) && (
        <div id="reportArea">
          {
            <Box data-testid="results-tabs">
              <AppBar position="static" color="default" elevation={1} style={{ marginBottom: '20px' }}>
                <Tabs
                  value={tab}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  {showParticipants && (
                    <Tab
                      label={t('pages.feedbackReport.reportData.participantFeedback')}
                      id="participant-feedback"
                      aria-controls="participant-feedback"
                      value="participant-feedback"
                      key="participant-feedback"
                    />
                  )}
                  {trainerData.map((item) => {
                    const identifier = `trainer-feedback-${item.trainerId}`;
                    return (
                      <Tab
                        label={`${t('pages.feedbackReport.reportData.trainerFeedback')} ${item.trainerId}`}
                        id={identifier}
                        aria-controls={identifier}
                        value={identifier}
                        key={identifier}
                      />
                    );
                  })}
                </Tabs>
              </AppBar>
              {participantData && (
                <TabPanel actualTab={tab} value="participant-feedback">
                  {parseParticipantFeedback(participantData)}
                </TabPanel>
              )}
              {trainerData.map((item) => {
                return parseTrainerFeedbackPanel(item);
              })}
            </Box>
          }
        </div>
      )}
    </>
  );
};
