import { FunctionComponent } from 'react';
import ChartBox from '../ChartBox';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { palette } from '../Report';

interface IChartNPSProps {
  title: string;
  data: string[];
}

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

export const ChartNPS: FunctionComponent<IChartNPSProps> = ({ title, data }) => {
  const getNPSArray = (originalArray: string[]) => {
    const NPS = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    originalArray.forEach((value) => {
      NPS[Number(value)]++;
    });

    return NPS;
  };

  return (
    <ChartBox title={title}>
      <Bar
        options={{
          animation: false,
          responsive: true,
          aspectRatio: 4 | 1,
          scales: {
            y: {
              ticks: {
                stepSize: 1,
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: false,
            },
            datalabels: {
              color: '#444',
              labels: {
                title: {
                  font: {
                    weight: 'bold',
                  },
                },
              },
              formatter: function (value) {
                return value !== 0 ? value : null;
              },
            },
          },
        }}
        data={{
          labels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
          datasets: [
            {
              data: getNPSArray(data),
              backgroundColor: palette[0],
            },
          ],
        }}
      />
    </ChartBox>
  );
};
