import { FunctionComponent, useContext, useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Theme,
  FormControl,
  InputLabel,
  Button,
  LinearProgress,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
  Input,
  SelectChangeEvent,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { IProductResponse, ISurveyMappingResponse, ISurveyResponse } from '@shared/interfaces';
import MicroFrontendContext from '../../components/MicroFrontendContext';
import { getApiService } from '../../api/api-request';

const useStyles = makeStyles((theme: Theme) => ({
  formMappings: {
    '& .MuiFormControl-root': {
      marginBottom: theme.spacing(2),
      display: 'block',
    },
    '& .MuiInputBase-root': {
      width: '100%',
      maxWidth: '500px',
    },
    '& .MuiButtonBase-root + *': {
      margin: theme.spacing(1),
    },
  },
}));

const surveyTypeList = [
  {
    label: 'Teilnehmer-Feedback',
    value: 'PARTICIPANT_FEEDBACK',
  },
  {
    label: 'Trainer-Feedback',
    value: 'TRAINER_FEEDBACK',
  },
];

const surveyDistributionTypes = ['LU', 'EMAIL', 'AU', 'PRINT', 'MANUAL'];
const disabledSurveyDistributionTypes = ['PRINT'];

interface IStep2Props {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  onNext: (...args: any) => void;
  onBack: (...args: any) => void;
  products?: IProductResponse[];
  formatNumbers?: string[];
  survey?: ISurveyResponse;
  surveyType?: string;
  surveyDistributionsType?: string[];
  surveyList: ISurveyResponse[];
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Step2: FunctionComponent<IStep2Props> = ({
  onNext,
  onBack,
  products,
  formatNumbers,
  survey,
  surveyType,
  surveyDistributionsType,
  surveyList,
}) => {
  const { t } = useTranslation();
  const { getJWT } = useContext(MicroFrontendContext);

  const [existingMappings, setExistingMappings] = useState<ISurveyMappingResponse[]>([]);
  const [surveySelected, setSurveySelected] = useState<string>();
  const [surveyTypeSelected, setSurveyTypeSelected] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [surveyDistributionTypeSelected, setSurveyDistributionTypeSelected] = useState<string[]>([]);

  const handleSurveyDistributionChange = (event: SelectChangeEvent<typeof surveyDistributionTypeSelected>) => {
    const {
      target: { value },
    } = event;
    setSurveyDistributionTypeSelected(typeof value === 'string' ? value.split(',') : value);
  };

  const handleSurveyChange = (event: SelectChangeEvent) => {
    setSurveySelected(event.target.value as string);
  };

  const handleSurveyTypeChange = (event: SelectChangeEvent) => {
    setSurveyTypeSelected(event.target.value as string);
  };

  const handleSubmit = async () => {
    if (surveySelected) {
      const survey = surveyList?.filter((a) => a.surveyId === surveySelected)[0];
      onNext({
        survey,
        surveyType: surveyTypeSelected,
        surveyDistributionsType: surveyDistributionTypeSelected,
      });
    }
  };

  useEffect(() => {
    setSurveySelected(survey?.surveyId);
    setSurveyTypeSelected(surveyType);
    if (surveyDistributionsType) {
      setSurveyDistributionTypeSelected(surveyDistributionsType);
    }
    const apiService = getApiService(getJWT);
    const getList = async () => {
      setLoading(true);
      if (products && products.length > 0) {
        await products?.map(async (e) => {
          const mapping = await apiService.getSurveyMappings('BOOKING_NUMBER', e.bnr);
          setExistingMappings((e) => e.concat(mapping.result));
        });
      } else if (formatNumbers && formatNumbers.length > 0) {
        await formatNumbers?.map(async (e) => {
          const mapping = await apiService.getSurveyMappings('FORMAT', e);
          setExistingMappings((e) => e.concat(mapping.result));
        });
      }
      setLoading(false);
    };
    getList();
  }, [survey?.surveyId, products, formatNumbers, surveyType, surveyDistributionsType]);

  const classes = useStyles();
  return (
    <Box component="div" pt={3} className={classes.formMappings}>
      {loading && <LinearProgress />}
      {(products && products.length > 0) || (formatNumbers && formatNumbers.length > 0) ? (
        <>
          <FormControl>
            <strong>
              {products && products.length > 0
                ? t('pages.mappingSurvey.steps.step3.selectedProducts')
                : t('pages.mappingSurvey.steps.step3.selectedFormats')}
            </strong>
            {products && products.length > 0
              ? products?.map((e) => (
                  <div key={e.bnr}>
                    {e.bnr}: {e.eventTitle}
                  </div>
                ))
              : formatNumbers?.map((e) => <div key={e}>{e}</div>)}
          </FormControl>

          {existingMappings.length > 0 && (
            <FormControl>
              <Alert severity="warning" icon={false}>
                <strong>{t('pages.mappingSurvey.steps.step2.mappingsExistedTitle')}</strong>
                <br />
                {t('pages.mappingSurvey.steps.step2.mappingsExistedHint')}
                <ul>
                  {existingMappings?.map((e) => (
                    <li key={e.idType}>
                      <p>
                        <b>{t('pages.mappingSurvey.steps.step2.product')}: </b>
                        {e.id}: {e.eventTitle} <br />
                        <b>{t('pages.mappingSurvey.steps.step2.survey')}: </b>
                        {e.surveyTitle} <br />
                        <b>{t('pages.mappingSurvey.steps.step3.selectedSurveyType')}: </b>
                        {t(`pages.mappingSurvey.surveyTypes.${e.surveyType}`)}
                      </p>
                    </li>
                  ))}
                </ul>
              </Alert>
            </FormControl>
          )}

          <FormControl>
            <InputLabel id="surveyType">{t('pages.mappingSurvey.steps.step2.surveyTypeSelectInputLabel')}</InputLabel>
            <Select
              labelId="surveyTypeLabel"
              id="surveyType"
              onChange={handleSurveyTypeChange}
              value={surveyTypeSelected ?? ''}
              label={t('pages.mappingSurvey.steps.step2.surveyTypeSelectInputLabel')}
            >
              {surveyTypeList?.map((s, i) => (
                <MenuItem key={i} value={s.value}>
                  {s.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel id="survey">{t('pages.mappingSurvey.steps.step2.selectInputLabel')}</InputLabel>
            <Select
              labelId="surveyLabel"
              id="survey"
              onChange={handleSurveyChange}
              value={surveyList && surveySelected ? surveySelected : ''}
              label={t('pages.mappingSurvey.steps.step2.selectInputLabel')}
            >
              {surveyList?.map((s, i) => (
                <MenuItem key={i} value={s.surveyId}>
                  {s.surveyTitle}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel id="survey-distribution-type-mutiple-checkbox-label">
              {t('pages.mappingSurvey.steps.step2.surveyDistributionTypeSelectInputLabel')}
            </InputLabel>
            <Select
              labelId="survey-distribution-type-mutiple-checkbox-label"
              id="survey-distribution-type-mutiple-checkbox"
              multiple
              value={surveyDistributionTypeSelected}
              onChange={handleSurveyDistributionChange}
              input={<Input />}
              renderValue={(selected) =>
                (selected as string[]).map((e) => t(`pages.mappingSurvey.surveyDistributionTypes.${e}`)).join(', ')
              }
              MenuProps={MenuProps}
            >
              {surveyDistributionTypes?.map((type) => (
                <MenuItem key={type} value={type} disabled={disabledSurveyDistributionTypes.includes(type)}>
                  <Checkbox checked={surveyDistributionTypeSelected.indexOf(type) > -1} />
                  <ListItemText
                    primary={
                      t(`pages.mappingSurvey.surveyDistributionTypes.${type}`) +
                      (disabledSurveyDistributionTypes.includes(type)
                        ? ' ' + t('pages.mappingSurvey.steps.step2.surveyDistributionTypeDisabled')
                        : '')
                    }
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <Button variant="outlined" onClick={onBack}>
              {t('pages.mappingSurvey.steps.step2.btnBackLabel')}
            </Button>
            <Button
              disabled={!surveySelected || !surveyTypeSelected}
              variant="contained"
              color="primary"
              component="button"
              onClick={handleSubmit}
            >
              {t('pages.mappingSurvey.steps.step2.btnLabel')}
            </Button>
          </FormControl>
        </>
      ) : (
        <>
          <FormControl>
            <Alert severity="warning">{t('pages.mappingSurvey.steps.step2.productNotFound')}</Alert>
          </FormControl>
          <FormControl>
            <Button variant="outlined" onClick={onBack}>
              {t('pages.mappingSurvey.steps.step2.btnBackLabel')}
            </Button>
          </FormControl>
        </>
      )}
    </Box>
  );
};

export default Step2;
